<template>
  <v-form :class="container" class="px-4" ref="form" v-model="valid" lazy-validation>
    <div :class="card">
      <div class="card-footer-splash"></div>
      <v-container class="pa-10">
          <v-row no-gutters justify="center">
            <v-col>
              <div :class="titleFont" style="text-align: center;">{{title}}</div>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="center" class="mt-6">
            <v-col cols="12" md="6">
              <v-text-field
                placeholder="Name"
                :class="$vuetify.breakpoint.smAndDown ? 'form-input-mobile' : 'form-input'"
                :rules="nameRules"
                required
                outlined
                color="#CBBEFF"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                placeholder="Email Address"
                :class="$vuetify.breakpoint.smAndDown ? 'form-input-mobile' : 'form-input'"
                :rules="emailRules"
                required
                outlined
                color="#CBBEFF"
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="center" class="mt-md-n6">
            <v-col cols="12">
              <v-textarea
                placeholder="Message"
                :class="$vuetify.breakpoint.smAndDown ? 'form-input-mobile' : 'form-input'"
                :rules="messageRules"
                v-model="message"
                color="#CBBEFF"
                outlined
            ></v-textarea>
            </v-col>
          </v-row>
          <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="center" class="mt-md-n6">
            <v-col cols="12" sm="9" md="12" lg="10" xl="8">
              <v-checkbox
                  color="#CBBEFF"
                  v-model="allowMarketing"
                  :label="marketing"
                  class="ml-2"
                  :class="$vuetify.breakpoint.smAndDown ? 'get-in-touch-marketing-checkbox-mobile' : 'get-in-touch-marketing-checkbox '"
                />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center">
              <Button v-if="!loading" :text="'Get in touch'" @click="submit" />
              <v-progress-circular
                indeterminate
                color="#ff6200"
                v-if="loading"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-snackbar v-model="snackbar">
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="#ff6200"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
          </v-snackbar>
        </v-container>
    </div>
  </v-form>
</template>

<script>
import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase/config';
import Button from './Button.vue';
import { mapWritableState } from "pinia";
import { useGetInTouchStore } from "@/stores/getintouch";
const config = require("@/config");

export default {
  name: "GetInTouch",
  components: {
    Button,
  },
  props: [
    "successMessage",
    "errorMessage",
    "marketing"
  ],
  data() {
    return {
      name: "",
      email: "",
      message: "",
      allowMarketing: false,
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
      ],
      messageRules: [
        v => !!v || this.entityType == 'agent' || 'Message is required',
      ],
      snackbar: false,
      snackbarMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapWritableState(useGetInTouchStore, ['title', 'hasRadioGroup', 'entityType']),
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-50px";
        case "sm":
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
    radioFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-19px";
        default:
          return "poppins-normal-white-15px";
      }
    },
    container() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "get-in-touch-form-dark-desktop";
        default:
          return "get-in-touch-form-dark-mobile";
      }
    },
    card() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "card-footer card-footer-desktop";
        default:
          return "card-footer card-footer-mobile";
      }
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          const getInTouch = httpsCallable(functions, config.functions.GET_IN_TOUCH);
          getInTouch({
            name: this.name,
            email: this.email,
            message: this.message,
            marketing: this.allowMarketing,
          })
          .then((result) => {
            this.snackbarMessage = this.successMessage;
            this.snackbar = true;
            this.loading = false;

            this.$gtm.trackEvent({
              event: 'subscribe-nwl',
              category: 'Subscribe',
              action: 'click',
              label: 'Subscribe to nwl',
              value: 0,
              noninteraction: false, // Optional
            });
          })
          .catch((error) => {
            this.snackbarMessage = this.errorMessage;
            this.snackbar = true;
            console.log(error);
            this.loading = false;
          });
        }
      });
    },
  }
};
</script>

<style>
.get-in-touch-form-dark-desktop {
  width: 100vw;
  background: linear-gradient(180deg, black 0%, #100032 15%, #310293aa 35%, black 30%);
}

.get-in-touch-form-dark-mobile {
  width: 100vw;
  background: linear-gradient(180deg, black 0%, #100032 5%, #310293aa 30%, black 30%);
}

.card-footer {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  border-radius: 28px;
  overflow: hidden;
  position: relative;
}

.card-footer-desktop {
  margin-top: 120px;
}

.card-footer-mobile {
  margin-top: 48px;
}

.card-footer-splash {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 70%;
  margin: auto;
  background: linear-gradient(270deg, #310293 25%, #9417F2 75%);
  filter: blur(120px);
}

.form-input .v-text-field__slot input {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 20px;
}

.form-input-mobile .v-text-field__slot input {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 20px;
}

.form-input .v-text-field__slot input::placeholder {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.form-input-mobile .v-text-field__slot input::placeholder {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.form-input .v-text-field__slot textarea {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 20px;
}

.form-input-mobile .v-text-field__slot textarea {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 20px;
}

.form-input .v-text-field__slot textarea::placeholder {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.form-input-mobile .v-text-field__slot textarea::placeholder {
  color: #CBBEFF;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.form-input fieldset {
  border: thin solid #3B3165;
  border-radius: 12px;
}

.form-input-mobile fieldset {
  border: thin solid #3B3165;
  border-radius: 12px;
}

.get-in-touch-marketing-checkbox .v-label {
  color: white;
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.get-in-touch-marketing-checkbox-mobile .v-label {
  color: white;
  font-family: var(--font-family-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.get-in-touch-marketing-checkbox .v-icon {
  color: #CBBEFF !important;
}

.get-in-touch-marketing-checkbox-mobile .v-icon {
  color: #CBBEFF !important;
}

</style>
