<template>
  <v-app>
    <v-system-bar app class="system-bar">
      <div :class="systemBarContent" class="wider d-flex flex-md-row flex-column align-start align-md-center justify-center">
        <div :class="systemBarFont" class="d-flex align-sm-center flex-grow-1 flex-shrink-0"><a class="d-none d-md-block" href="https://republic.com" target="_blank" rel="noreferrer"><v-img :src="require('@/assets/img/home/republic.svg')" max-width="24px" max-height="28px" style="float: left; margin: 6px;"></v-img></a><div>Our Reg D Public Token Offering on <a href="https://republic.com/timeworx" target="_blank" rel="noreferrer">Republic.com</a> is <span style="font-weight: 700">now live</span>!</div></div>
        <div :class="$vuetify.breakpoint.smAndDown ? 'wider' : ''" class="d-flex flex-row justify-space-between justify-sm-start mt-3 mt-md-0">
          <a href="https://republic.com/timeworx" target="_blank"  rel="noreferrer">
            <v-btn
              medium
              rounded
              class="system-bar-button poppins-semi-bold-14px ml-md-8"
            >Get Started</v-btn>
          </a>
          <v-btn
              medium
              rounded
              outlined
              :to="readMore"
              class="system-bar-button-outlined poppins-semi-bold-14px ml-sm-8"
              v-if="readMore"
            >Learn more</v-btn>
        </div>
      </div>
    </v-system-bar>
    <NavBar
      :isTranslucent="!this.scrolled"
      :twitter="footerData.socialProps.twitter"
      :telegram="footerData.socialProps.telegram"
      :discord="footerData.socialProps.discord"
    />
    <v-main class="pa-0">
      <router-view style="padding-top: 160px;" />
    </v-main>
    <Footer v-bind="footerData" />
    <v-img
      v-if="showBackToTop"
      :src="require('@/assets/img/back-to-top.svg')"
      style="position: fixed; bottom: 5%; right: 5%; z-index: 5; cursor: pointer;"
      @click="onBackToTopClicked"
    ></v-img>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapStores } from "pinia";
import AllowlistRegistration from "./components/AllowlistRegistration.vue";
import NavBar from "./components/NavBar.vue";
import { useScrollStore } from "@/stores/scroll";
import { footerData } from "./data";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    AllowlistRegistration,
    NavBar,
    Footer,
  },
  props: ["HomeData"],
  data() {
    return {
      readMore: { path: 'article?republic-of-tix' },
      scrolled: false,
      footerData: footerData,
    };
  },
  metaInfo() {
    return {
      title: 'Timeworx.io | Make your time work for you',
      titleTemplate:'%s | Timeworx.io'
    }
  },
  computed: {
    ...mapStores(useScrollStore),
    ...mapState(useScrollStore, ["scrollable"]),
    showBackToTop() {
      return this.scrolled && !this.$isMobile();
    },
    systemBarContent() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
        return "system-bar-desktop";
        case "sm":
        case "xs":
          return "system-bar-mobile";
      }
    },
    systemBarFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "system-bar-text poppins-normal-14px";
        case "sm":
        case "xs":
          return "system-bar-text poppins-normal-12px";
      }
    },
  },
  methods: {
    ...mapActions(useScrollStore, ["setScrollable"]),
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      this.scrolled = this.scrollable && currentScrollPosition > 0;
    },
    onBackToTopClicked() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    navigate(link) {
      window.open(link, '_blank', 'noreferrer');
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.setScrollable(true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
.wider {
  width: 100%;
  max-width: 100% !important;
}

.system-bar {
  min-width: 100vw !important;
  background: #CBBEFF;
  height: auto !important;
}

.system-bar-desktop {
  max-width: 1920px !important;
  padding: 20px 160px;
  margin: 0px auto;
}

.system-bar-mobile {
  max-width: 1920px !important;
  padding: 20px;
}

.system-bar-button {
  color: white !important;
  background: #330694 !important;
  text-transform: unset !important;
  padding: 0px 24px !important;
  min-width: 0 !important;
}

.system-bar-button-outlined {
  color: #330694 !important;
  border-color: #79757F !important;
  text-transform: unset !important;
  padding: 0px 24px !important;
  min-width: 0 !important;
  font-weight: 500;
}

.system-bar-text {
  color: black;
}

.underline {
  text-decoration: underline;
}
</style>
