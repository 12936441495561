<template>
  <Page title="Cookie Policy">
    <v-row no-gutters justify="center" class="mb-16">
      <v-col no-gutters>
        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            Timeworx.io uses cookies to enhance user experience, analyze website traffic, and improve our services. By using our website, you consent to the use of cookies as described in this policy.
          </p>

          <p>
            If you have any questions about our use of cookies, please contact us at: contact@timeworx.io.
          </p>

          <p>
            We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Please check this page periodically for updates.
          </p>

          <p>
            This Cookie Policy is effective as of December 2024.
          </p>

          <h2 :class="subtitleFont" class="highlight">Consent</h2>
        
          <p>
            By using our website, you consent to the placement of cookies on your device. You can manage your cookie preferences through your browser settings. Note that disabling certain cookies may affect the functionality of the website.
          </p>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">What are Cookies</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">

          <p>
            Cookies are small text files that are stored on your device (computer, smartphone, etc.) when you visit a website. They are widely used to make websites work more efficiently and provide a better user experience.
          </p>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">How We Use Cookies</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            We use cookies for the following purposes:
          </p>

          <ul class="priv-list">
            <li><span class="bold">Essential Cookies</span>: These cookies are necessary for the basic functioning of our website. They enable you to navigate the site and use essential features.</li>
            <li><span class="bold">Analytics Cookies</span>: We use these cookies to analyze how users interact with our website, such as which pages are visited most often, and how users move around the site. This helps us improve the website's performance and user experience.</li>
            <li><span class="bold">Functional Cookies</span>: These cookies allow us to remember your preferences (such as language settings) and provide enhanced features to personalize your experience.</li>
            <li>
              <span class="bold">Advertising Cookies</span>: These cookies are used to deliver advertisements that are relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.
            </li>
          </ul>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">Types of Cookies We Use</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <ul class="priv-list">
            <li><span class="bold">First-Party Cookies</span>: These cookies are set by Timeworx.io directly.</li>
            <li><span class="bold">Third-Party Cookies</span>: These cookies are set by third-party domains. For example, we may use Google Analytics to track website traffic, which sets cookies on your device.</li>
          </ul>
        </div>

        <h2 :class="[subtitleFont, xSpacing]">Specific Cookies Used</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <ul class="priv-list">
            <li><span class="bold">Google Analytics</span>: Used to track website traffic and user behavior.</li>
            <li><span class="bold">HubSpot</span>: Used for marketing automation and customer relationship management.</li>
          </ul>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">How to Manage Cookies</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            Most web browsers allow you to control cookies through their settings. You can set your browser to block all cookies, accept only first-party cookies, or prompt you before a cookie is placed.
          </p>

          <p>
            Various third-party tools and browser extensions are available to help you manage and block cookies.
          </p>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">
          Data Privacy
        </h2>

        <div :class="[contentFont, spacing, 'spacing-last']" class="reflective-card">
          <p>
            For information about how we handle your personal data, please refer to our <router-link to="/privacy">Privacy Policy</router-link>. We are committed to safeguarding user data with blockchain-backed security and federated learning to keep information confidential.
          </p>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy Policy"
  },
  components: { Page },
  props: [],
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    subtitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-42px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    headlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-64px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "spacing-desktop";
        case "sm":
        case "xs":
          return "spacing-mobile";
      }
    },
    xSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-spacing-desktop";
        case "sm":
        case "xs":
          return "x-spacing-mobile";
      }
    },
  },
};
</script>

<style scoped>
.priv-list {
  list-style-type: none;
}

.priv-list li:before {
 content: "\2022";
 padding-right: 0.5em;
 color: white;
}

.highlight {
  color: #D554FF;
}

.spacing-desktop {
  padding: 80px 160px;
  margin-bottom: 80px;
}

.spacing-mobile {
  padding: 20px;
  margin-bottom: 40px;
}

.spacing-last {
  margin-bottom: 0px !important;
}

.x-spacing-desktop {
  padding: 0px 160px;
  margin-bottom: 40px !important;
}

.x-spacing-mobile {
  padding: 0px 20px;
  margin-bottom: 20px !important;
}

* a {
  color: inherit !important;
}

.bold {
  font-weight: 700;
}
</style>
