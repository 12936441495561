<template>
  <div :class="background">
    <v-container class="page-container px-5">
      <transition appear name="on-header" v-if="top && title">
        <v-row no-gutters :class="top" class="align-center" :style="{ 'background': `url(${topImage}) no-repeat center` }">
          <v-col no-gutters>
            <transition appear name="on-heading" v-on:after-enter="onHeadingEntered()">
              <h1 :class="heading" class="mt-md-n16" :style="headingStyle" style="max-width: 100% !important">{{ title }}</h1>
            </transition>
          </v-col>
        </v-row>
      </transition>
      <slot />
    </v-container>
  </div>
</template>

<script>

export default {
  name: "Page",
  props: ["title", "topImage"],
  data() {
        return {
            headingStyle: {
                'transform': 'scale(0)'
            },
        }
    },
  computed: {
    background() {
        switch (this.$vuetify.breakpoint.name) {
            case "xl":
            case "lg":
            case "md":
                return "background-desktop";
            case "sm":
            case "xs":
                return "background-mobile";
        }
    },
    top() {
        switch (this.$vuetify.breakpoint.name) {
            case "xl":
            case "lg":
            case "md":
                return "top-desktop";
            case "sm":
            case "xs":
                return "top-mobile";
        }
    },
    heading() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
              return "heading heading-desktop poppins-bold-white-80px";
          case "sm":
          case "xs":
              return "heading heading-mobile poppins-bold-white-24px";
      }
    },
  },
  methods: {
    onHeadingEntered() {
        this.headingStyle = {
            'transform': 'scale(1)'
        }
    },
  }
};
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1480px;
    margin: auto;
}

.top-desktop {
    background-size: cover;
    margin-top: -80px;
    margin-bottom: -80px;
    width: 1920px;
    height: 540px;
}

.top-mobile {
    background-size: cover !important;
    width: 180%;
    height: 220px;
    margin-bottom: -40px;
}

.background-desktop {
    background: linear-gradient(180deg, #310293 0px, #100032 400px, #000000 900px);
}

.background-mobile {
  background: linear-gradient(180deg, #310293 50px, #100032 250px, #000000 350px);
}

.heading {
    text-align: center;
    width: 100vw !important;
    margin: auto;
}

.heading-desktop {
    line-height: 96px;
}

.heading-mobile {
    line-height: normal;
}

.on-header-enter-active {
    animation: fade-in-header 0.9s;
}

@keyframes fade-in-header {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(50%) scale(1.5);
    opacity: 0.15;
  }
  50% {
    transform: translateY(10%) scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.on-heading-enter-active {
    animation: fade-in-heading 0.6s;
    animation-delay: 0.4s;
}

@keyframes fade-in-heading {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
