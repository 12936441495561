<template>
  <Page :title="title">
    <transition appear name="on-tasks">
      <v-row justify="center" align="end" class="ma-0">
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Image Processing
          </div>
          <lottie :options="taskAnimationOptions1" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Text analysis
          </div>
          <lottie :options="taskAnimationOptions2" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Breaking down videos
          </div>
          <lottie :options="taskAnimationOptions3" class="px-sm-10 px-lg-0" />
        </v-col>
        <v-col :cols="pictogramNumColumns" align="center" justify="space-between">
          <div :class="[normalTextFont, 'px-0', 'px-sm-8']" :style="{ 'text-align': 'center' }">
            Media augmentation
          </div>
          <lottie :options="taskAnimationOptions4" class="px-sm-10 px-lg-0" />
        </v-col>
      </v-row>
    </transition>

    <transition appear name="on-header-bottom">
      <v-row no-gutters class="mt-sm-8 mt-lg-16">
        <v-col>
          <v-row justify="center">
            <v-col cols="10" lg="8">
              <div :class="titleFont" style="text-align: center">
                Get the ground truth out of your data with more diversity and less bias
              </div>
            </v-col>
          </v-row>
          <v-row class="mx-2" justify="space-around">
            <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
              <div
                :class="normalTextFont"
                :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
              >
              Our agents span the entire globe coming from various backgrounds and having different levels of education.
              </div>
            </v-col>
            <v-col :cols="bottomTextNumColumns" class="pa-lg-6">
              <div
                :class="normalTextFont"
                :style="{ 'text-align': 'center', 'line-height': bottomLineHeight }"
              >
              Aside from processing your data, our digital workforce can also provide specific media that you need to build your model.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </transition>

    <v-row class="partners my-5 my-md-16">
      <v-col class="align-start mx-5 invisible" v-animate-onscroll="{down: 'animated fadeIn'}">
        <div :class="partnerHeadlineFont" class="partners-headline">Trusted by</div>
      </v-col>
      <v-col class="invisible" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
        <partners class="my-md-10 px-5 px-md-0" />
        <hr class="separator mx-5 mx-md-0" />
      </v-col>
    </v-row>

    <!-- Section 01 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">01</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Get started</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont" class="highlight mb-5 mb-md-0">Affordable Data Labeling at Scale</h1>
      <v-col cols="12" md="7" class="align-self-center">
        <div :class="normalTextFont" class="justified">Leverage our diversified and global workforce of agents, either human or AI, without having to worry about the cost of scaling. Whether you're building, training or fine-tuning ML models, or just simply interested in plain ol’ data processing, it has never been easier to integrate crowdsourced human feedback in your data workflows.</div>
        <div class="wider d-flex flex-column flex-md-row justify-center align-center justify-md-start mt-6">
          <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
            <v-btn elevation="3" rounded :style="{
              'background-color': '#ff6200',
              'color': 'white',
              'z-index': 2,
              'text-transform': 'unset !important',
            }">
              <div class="poppins-semi-bold-white-14px">Set up a meeting with our experts</div>
            </v-btn>
          </a>
          <TooltipButton
              :color="'#ff6200'"
              text="Go to console"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
              :outlined="true"
              class="ml-md-10 mt-md-0 mt-6">
            </TooltipButton>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="4" class="justify-space-around align-self-center"">
        <v-img
          class="mt-10 mt-md-0"
          :src="require('@/assets/img/business/business.png')"
          max-width="100%"
          contain>
        </v-img>
      </v-col>
    </v-row>

    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont" class="remove-highlight">Benefits</h1>
      <div class="d-flex flex-md-row flex-column justify-md-space-between my-10">
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Flexible</h2>
          <div :class="smallTextFont" class="justified">Timeworx.io enables you to seamlessly access our network of distributed agents on demand for a fraction of the price.</div>
        </div>
        <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
        <div class="horizontal-divider d-md-none my-5"></div>
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Accurate</h2>
          <div :class="smallTextFont" class="justified">Timeworx.io automatically generates more agents as needed to maintain high accuracy levels at no additional cost to you.</div>
        </div>
        <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
        <div class="horizontal-divider d-md-none my-5"></div>
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Optimized</h2>
          <div :class="smallTextFont" class="justified">The more you work with us, the more efficient our systems become as we are able to gradually replace human agents with AI.</div>
        </div>
      </div>
    </v-row>

    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont">Responsive Pricing</h1>
      <div :class="normalTextFont" class="justified mt-3 mt-md-5 ">At the outset, our platform provides you with a recommended price for your data processing needs, determined by a dynamic pricing algorithm, which empowers you and our agents through a flexible and responsive bidding mechanism.<br /><br />You can always diverge from our recommendation as you see fit, and express your valuation directly, adapting to your needs and budgetary considerations.</div>
      <div class="wider d-flex flex-column flex-md-row justify-center align-center mt-6 mt-md-16">
        <TooltipButton
            :color="'#ff6200'"
            text="Get your quote"
            :tooltip="'Coming soon'"
            :tooltipColor="'#E8EAED'"
            :tooltipTextColor="'#3B00A0'">
          </TooltipButton>

          <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
            <v-btn elevation="3" rounded :style="{
              'background-color': 'black',
              'border': 'thin #ff6200 solid',
              'color': 'black',
              'z-index': 2,
              'text-transform': 'unset !important',
            }" class="ml-md-10 mt-md-0 mt-6">
              <div class="poppins-semi-bold-white-14px">Contact us</div>
            </v-btn>
          </a>
      </div>
    </v-row>

    <!-- Section 02 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">02</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Annotate your data</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <h1 :class="sectionHeadlineFont">All-in-one toolbox</h1>
          <div :class="normalTextFont" class="justified mt-3 mt-md-5 ">We support a wide array of data labeling services across a variety of domains, from natural language processing, sentiment analysis, ranking and classification, to image & video processing and computer vision.<br /><br />With an emphasis on speed, accuracy and high throughput, we augment our human agents with AI-assisted annotation tools to provide you with the best-in-class results.</div>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont" class="remove-highlight">Supported data types and labeling services</h1>
      <div class="wider mt-10">
        <div :class="emphasisTextFont" class="emphasis mb-3">Text</div>
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="smallTextFont" class="pr-md-16">Anything in written form such as blog posts, tweets, product reviews, or any other type of written content</v-col>
          <v-col cols="12" md="6" :class="smallTextFont">Text analysis methods include sentiment analysis, topic modelling, keyword extraction, named entity recognition, ranking</v-col>
        </v-row>
        <div class="horizontal-divider faded my-3 my-md-10"></div>
      </div>
      <div class="wider">
        <div :class="emphasisTextFont" class="emphasis mb-3">Image</div>
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="smallTextFont" class="pr-md-16">Any type of graphics or images, ranging from user-uploaded photos to satellite imagery</v-col>
          <v-col cols="12" md="6" :class="smallTextFont">Image analysis methods include object detection, image classification, panoptic / class / instance segmentation, facial detection & recognition, human pose estimation, ranking</v-col>
        </v-row>
        <div class="horizontal-divider faded my-3 my-md-10"></div>
      </div>
      <div class="wider">
        <div :class="emphasisTextFont" class="emphasis mb-3">Video</div>
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="smallTextFont" class="pr-md-16">Any moving visual images from short video clips to full-length movies</v-col>
          <v-col cols="12" md="6" :class="smallTextFont">Video analysis methods include scene recognition, action recognition, object tracking, video summarization</v-col>
        </v-row>
        <div class="horizontal-divider faded my-3 my-md-10"></div>
      </div>
      <div class="wider">
        <div :class="emphasisTextFont" class="emphasis mb-3">Audio</div>
        <v-row no-gutters class="my-3 my-md-6">
          <v-col cols="12" md="6" :class="smallTextFont" class="pr-md-16">Any type of sound, music, or speech content</v-col>
          <v-col cols="12" md="6" :class="smallTextFont">Audio analysis methods include speech recognition, music classification, audio fingerprinting, emotion recognition from speech</v-col>
        </v-row>
        <div class="horizontal-divider faded my-3 my-md-10"></div>
      </div>
      <div class="wider">
        <div :class="emphasisTextFont" class="emphasis mb-3">Tabular</div>
        <v-row no-gutters>
          <v-col cols="12" md="6" :class="smallTextFont" class="pr-md-16">Structured data that's organised in columns and rows, similar to what you'd see in a spreadsheet or a database table</v-col>
          <v-col cols="12" md="6" :class="smallTextFont">Tabular data analysis methods include statistical analysis, regression analysis, time series analysis, missing data extrapolation</v-col>
        </v-row>
        <div class="horizontal-divider faded my-3 my-md-10"></div>
      </div>
      <h1 :class="sectionHeadlineFont">Have we missed anything?<br />No worries, we can build that for you!</h1>
      <div class="wider d-flex flex-column flex-md-row justify-center align-center my-6 my-md-16">
        <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
            <v-btn elevation="3" rounded :style="{
              'background-color': '#ff6200',
              'color': 'white',
              'z-index': 2,
              'text-transform': 'unset !important',
            }">
              <div class="poppins-semi-bold-white-14px">Get in touch</div>
            </v-btn>
          </a>
          <TooltipButton
              :color="'#ff6200'"
              text="Label your data"
              :tooltip="'Coming soon'"
              :tooltipColor="'#E8EAED'"
              :tooltipTextColor="'#3B00A0'"
              :outlined="true"
              class="ml-md-10 mt-md-0 mt-6">
            </TooltipButton>
      </div>
    </v-row>

    <!-- Section 03 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">03</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Get the ground truth</div>
        <v-row no-gutters class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-col cols="12" md="6" class="align-self-center">
            <h1 :class="sectionHeadlineFont" class="remove-highlight mb-3 mb-md-6">Quality out of the box</h1>
            <div :class="normalTextFont" class="justified">Our approach is trustless: no Agent is considered trusted, whether human or AI.<br /><br />Your data will always be processed by multiple agents concurrently, and all outcomes need to be deemed correct only through their consensus.</div>
            <h1 :class="sectionHeadlineFont" class="remove-highlight my-3 my-md-6">You are in full control</h1>
            <div :class="normalTextFont" class="justified">Configure your custom replication factor or even provide your own mathematical consensus.</div>
            <div class="wider d-flex flex-column flex-md-row justify-center justify-md-start align-center mt-3 mt-md-12">
              <TooltipButton
                :color="'#ff6200'"
                text="Label your data"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'">
              </TooltipButton>

              <a href="https://docs.timeworx.io/the-solution/overview" target="_blank">
                <v-btn elevation="3" rounded :style="{
                  'background-color': 'black',
                  'border': 'thin #ff6200 solid',
                  'color': 'black',
                  'z-index': 2,
                  'text-transform': 'unset !important',
                }" class="ml-md-10 mt-md-0 mt-6">
                  <div class="poppins-semi-bold-white-14px">Read more</div>
                </v-btn>
              </a>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="7" :class="negativePaddingImage">
            <div :class="replicationImage"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Section 04 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">04</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Generate new data</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <h1 :class="sectionHeadlineFont" class="remove-highlight mb-3 mb-md-6">Don’t have enough data for your model?</h1>
          <div :class="normalTextFont">No worries, we can help with that too.<br /><br />Based on your prompts, human agents can produce:</div>
          <ul :class="mediumTextFont" class="highlight bold my-md-8" style="list-style-type: decimal">
            <li class="my-1 my-md-3">images and video directly from their camera feeds</li>
            <li class="my-1 my-md-3">audio data directly from their microphones</li>
            <li class="my-1 my-md-3">textual data directly from their keyboards and imaginations</li>
          </ul>
          <div :class="normalTextFont" class="mt-6">Your control ranges from the details of your prompts to the desired quality parameters of your requested outputs.<br /><br />Synthetic data is also available upon request, based on state-of-the-art GenAI models.</div>
          <div class="wider d-flex flex-column flex-md-row justify-center align-center my-6 my-md-16">
            <TooltipButton
                :color="'#ff6200'"
                text="Get started"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'">
              </TooltipButton>

              <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
                <v-btn elevation="3" rounded :style="{
                  'background-color': 'black',
                  'border': 'thin #ff6200 solid',
                  'color': 'black',
                  'z-index': 2,
                  'text-transform': 'unset !important',
                }" class="ml-md-10 mt-md-0 mt-6">
                  <div class="poppins-semi-bold-white-14px">Contact us</div>
                </v-btn>
              </a>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Section 05 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">05</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Connect the dots</div>
        <v-row no-gutters class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <v-col cols="12" md="6" class="align-self-center">
            <h1 :class="sectionHeadlineFont" class="remove-highlight mb-3 mb-md-6">Simple made easy</h1>
            <div :class="normalTextFont" class="justified">
              We have designed data processing as a pipeline with multiple stages through which data can flow.
              <br /><br />
              Each stage defines how the data is processed, performs the task and passes the outcome on to the next stage.
              <br /><br />
              Drag & drop components, connect the dots and let your data flow!
            </div>
            <div class="wider d-flex flex-column flex-md-row justify-center justify-md-start align-center mt-3 mt-md-12">
              <TooltipButton
                :color="'#ff6200'"
                text="Create your first pipeline"
                :tooltip="'Coming soon'"
                :tooltipColor="'#E8EAED'"
                :tooltipTextColor="'#3B00A0'">
              </TooltipButton>

              <a href="https://docs.timeworx.io/the-solution/pipelines#building-blocks" target="_blank">
                <v-btn elevation="3" rounded :style="{
                  'background-color': 'black',
                  'border': 'thin #ff6200 solid',
                  'color': 'black',
                  'z-index': 2,
                  'text-transform': 'unset !important',
                }" class="ml-md-10 mt-md-0 mt-6">
                  <div class="poppins-semi-bold-white-14px">Read more</div>
                </v-btn>
              </a>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="7" :class="negativePaddingImage">
            <div :class="pipelineImage"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Section 06 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">06</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Customize your crowd</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <h1 :class="sectionHeadlineFont" class="remove-highlight mb-3 mb-md-6">Precision at scale</h1>
          <div :class="normalTextFont">Timeworx.io gives you seamless access to our fully managed workforce of agents that span the entire globe coming from various backgrounds and having different levels of education.<br /><br />But sometimes you need to be able to carefully pick the data labelers that you work with. And we get that. That’s why we've designed a flexible system where you have a choice.</div>
          <div class="d-flex flex-md-row flex-column justify-md-space-between my-10">
            <div class="d-flex flex-column wider">
              <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Segment your audience</h2>
              <div :class="smallTextFont" class="justified">Filter our labeling workforce by location, age, spoken languages, and many more</div>
            </div>
            <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
            <div class="horizontal-divider d-md-none my-5"></div>
            <div class="d-flex flex-column wider">
              <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Work with your team</h2>
              <div :class="smallTextFont" class="justified">Take advantage of our data labeling software with your own data labeling team</div>
            </div>
            <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
            <div class="horizontal-divider d-md-none my-5"></div>
            <div class="d-flex flex-column wider">
              <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">Scale your own crowd</h2>
              <div :class="smallTextFont" class="justified">Leverage our platform as a whitelabel for your own crowd of labelers</div>
            </div>
          </div>
          <div class="wider d-flex flex-column flex-md-row justify-center align-center my-6 my-md-16">
            <a href="https://calendar.app.google/Kg1gxLhKBUTWMLpGA" target="_blank">
              <v-btn elevation="3" rounded :style="{
                'background-color': '#ff6200',
                'color': 'white',
                'z-index': 2,
                'text-transform': 'unset !important',
              }">
                <div class="poppins-semi-bold-white-14px">Contact us</div>
              </v-btn>
            </a>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Section 07 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">07</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Explore our case studies</div>
        <div class="reflective-card mt-5 mt-md-10" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
          <div :class="normalTextFont" class="mb-md-10 justified">Data processing is an integral part of our society with the ability to transform all industries. Instead of considering it as a vertical service for a specific sector, we recommend looking at it as a horizontal service that drives innovation across all industry sectors.</div>
          <div class="d-flex wider flex-md-row flex-column justify-md-space-between my-10">
            <router-link to="/article?ar-meets-ai" target="_blank">
              <div class="blog-card" :style="{ 'max-width': blogImageSize }">
                <img :src="require('@/assets/img/aboutus/blog/blog1.png')" width="100%" contain />
                <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">Augmented Reality meets AI</div>
                <div class="poppins-normal-white-16px px-6 secondary-text">by Radu Marin</div>
                <div class="poppins-normal-white-14px px-6 pb-12">4 min read</div>
              </div>
            </router-link>
            <router-link to="/article?ml-smart-waste-management" target="_blank">
              <div class="blog-card my-5 my-md-0" :style="{ 'max-width': blogImageSize }">
                <img :src="require('@/assets/img/aboutus/blog/blog2.png')" width="100%" contain />
                <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">ML and smart waste management</div>
                <div class="poppins-normal-white-16px px-6 secondary-text">by Alex Gherghina</div>
                <div class="poppins-normal-white-14px px-6 pb-12">4 min read</div>
              </div>
            </router-link>
            <router-link to="/article?ai-driven-future-of-pharma" target="_blank">
              <div class="blog-card" :style="{ 'max-width': blogImageSize }">
                <img :src="require('@/assets/img/aboutus/blog/blog3.png')" width="100%" contain />
                <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">The AI-Driven Future of Pharma - from Innovation to Ethics</div>
                <div class="poppins-normal-white-16px px-6 secondary-text">by Ana Nistor</div>
                <div class="poppins-normal-white-14px px-6 pb-12">5 min read</div>
              </div>
            </router-link>
          </div>
          <div class="wider d-flex justify-center my-5 my-md-10">
            <router-link to="/blog" target="_blank">
              <v-btn elevation="3" outlined rounded class="px-8" :style="{
                'color': 'white',
                'border-color': '#FF5800',
                'z-index': 2,
                'text-transform': 'unset !important',
              }">
                <div class="poppins-semi-bold-white-14px">Read all articles</div>
              </v-btn>
            </router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue"
import Partners from "../Partners.vue";
import TooltipButton from "../TooltipButton.vue";
import Lottie from "vue-lottie";
import taskAnimation1 from "@/assets/business/1-image-processing.json";
import taskAnimation2 from "@/assets/business/2-text-analysing.json";
import taskAnimation3 from "@/assets/business/3-video.json";
import taskAnimation4 from "@/assets/business/4-media-augmentation.json";

export default {
  name: "Roadmap",
  components: {
    Page,
    Lottie,
    Partners,
    TooltipButton
  },
  props: ["title"],
  data() {
    return {
      taskAnimationOptions1: { animationData: taskAnimation1, autoplay: true },
      taskAnimationOptions2: { animationData: taskAnimation2, autoplay: true },
      taskAnimationOptions3: { animationData: taskAnimation3, autoplay: true },
      taskAnimationOptions4: { animationData: taskAnimation4, autoplay: true },
    }
  },
  mounted() {
    setTimeout(() => {
        window.scrollBy(0, 1)
        window.scrollBy(0, -1)
    }, 1500);
  },
  computed: {
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return "poppins-bold-white-50px";
          case "md":
          case "sm":
            if (!this.$isMobile()) {
              return "poppins-bold-white-40px";
            } else {
              return "poppins-bold-white-25px";
            }
          case "xs":
            return "poppins-bold-white-25px";
      }
    },
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-32px";
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    partnerHeadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "text-center poppins-normal-white-34px";
        case "sm":
        case "xs":
          return "text-center poppins-normal-white-16px";
      }
    },
    sectionHeaderFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-64px";
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    sectionHeadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "highlight poppins-bold-white-42px";
        case "sm":
        case "xs":
          return "highlight poppins-bold-white-24px";
      }
    },
    sectionSubheadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-36px";
        case "sm":
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    mediumTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-24px";
          case "sm":
          case "xs":
            return "poppins-normal-white-16px";
      }
    },
    emphasisTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-20px";
          case "sm":
          case "xs":
            return "poppins-normal-white-16px";
      }
    },
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-20px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    smallTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-16px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    pictogramNumColumns() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return 3;
        case "md":
        case "sm":
        case "xs":
          return 6;
      }
    },
    bottomLineHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "30px";
        default:
          return "20px";
      }
    },
    bottomTextNumColumns() {
      if (this.$isMobile()) {
        return 12;
      } else {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
            return 6;
          case "md":
            return 5;
          case "xs":
          case "sm":
            return 4;
        }
      }
    },
    benefitGapStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return { 'gap': '48px' };
        case "md":
        case "sm":
        case "xs":
          return { 'gap': '12px' };
      }
    },
    benefitIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return 36;
        case "md":
        case "sm":
        case "xs":
          return 24;
      }
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "padding-desktop";
        case "sm":
        case "xs":
          return "padding-mobile";
      }
    },
    xPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-padding-desktop";
        case "sm":
        case "xs":
          return "x-padding-mobile";
      }
    },
    negativePaddingImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "negative-padding-image-desktop";
        case "sm":
        case "xs":
          return "negative-padding-image-mobile";
      }
    },
    replicationImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "replication-image-desktop";
        case "sm":
        case "xs":
          return "replication-image-mobile";
      }
    },
    pipelineImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "pipeline-image-desktop";
        case "sm":
        case "xs":
          return "pipeline-image-mobile";
      }
    },
    blogImageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "90%";
        case "sm":
        case "xs":
          return "100%";
      }
    },
  },
};
</script>

<style scoped>
.partners {
    max-width: 1480px;
    overflow: hidden;
}

.partners-headline {
    color: #3B3165;
    letter-spacing: 10.2px;
    text-align: left !important;
    display: block;
}

.blog-card {
  border-radius: 12px;
  background: #100032;
}

.separator {
    border: 0px;
    height: 2px;
    background: linear-gradient(90deg, #3B3165 20%, #CBBEFF 50%, #3B3165 80%);
}

.horizontal-divider {
  height: 1px !important;
  width: 100% !important;
  background-color: white !important;
}

.faded {
  background-color: #3B3165 !important;
}

.vertical-divider {
  width: 1px !important;
  background-color: white !important;
}

.highlight {
  color: #D554FF;
}

.remove-highlight {
  color: white !important;
}

.emphasis {
  color: #FF5800 !important;
  font-weight: 700;
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.secondary-text {
  color: #CBBEFF;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.padding-desktop {
  padding: 80px 160px;
}

.padding-mobile {
  padding: 20px;
}

.x-padding-desktop {
  padding: 0px 160px;
}

.x-padding-mobile {
  padding: 0px;
}

.wider {
  width: 100%;
  max-width: 100% !important;
}

.replication-image-desktop {
    background: url('~@/assets/img/business/replication.png') no-repeat center;
    background-size: contain;
    width: 100%;
    height: 100%;
}

.replication-image-mobile {
    background: url('~@/assets/img/business/replication.png') no-repeat center;
    background-size: contain;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 360px;
}

.pipeline-image-desktop {
    background: url('~@/assets/img/business/pipelines.png') no-repeat center;
    background-size: contain;
    width: 100%;
    height: 100%;
}

.pipeline-image-mobile {
    background: url('~@/assets/img/business/pipelines.png') no-repeat center;
    background-size: contain;
    width: calc(100% + 40px);
    height: 100%;
    min-height: 300px;
}

.negative-padding-image-desktop {
  margin-top: -80px;
  margin-bottom: -80px;
  margin-right: -160px;
}

.negative-padding-image-mobile {
  margin-left: -20px !important;
  margin-right: -20px !important;
  margin-bottom: -20px !important;
}

.button {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    color: white;
    text-transform: unset;
}

.outlined {
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.20) !important;
}

.opaque {
  opacity: 0;
}

.on-tasks-enter-active {
  animation-name: slideInLeftWithFade;
  -webkit-animation-name: slideInLeftWithFade;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transform: scale(0);
}

.on-header-bottom-enter-active {
  -webkit-animation-name: slideInUpWithFade;
  animation-name: slideInUpWithFade;
  animation-duration: 1s;
  animation-delay: 1s;
  visibility: hidden;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.slideInLeftWithFade {
    -webkit-animation-name: slideInLeftWithFade;
    animation-name: slideInLeftWithFade
}


@-webkit-keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform:translateZ(0);
  }
}

@keyframes slideInUpWithFade {
  0% {
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInUpWithFade {
  -webkit-animation-name: slideInUpWithFade;
  animation-name: slideInUpWithFade;
}

.invisible {
    opacity: 0;
}
</style>
