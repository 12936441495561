<template>
  <v-layout row justify-center>
    <v-app-bar
      app
      :color="isTranslucent ? 'transparent' : 'black'"
      :elevation="isTranslucent ? 0 : 5"
    >
      <v-container no-gutters style="max-width: 1920px;">
        <v-row justify="space-between" align="center" no-gutters class="ml-lg-4 mr-lg-4">
          <v-col cols="4">
            <div @click="onLogoClicked" style="cursor: pointer">
              <img
                :src="
                  isTranslucent
                    ? require('@/assets/img/logo/logo-white-secondary-tagline.svg')
                    : require('@/assets/img/logo/logo-white_Tcolor-secondary-tagline.svg')
                "
                :class="appBarLogoClass"
              />
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="8">
            <v-toolbar-items class="d-flex">
              <div
                v-if="debugbreakpoint"
                style="color: green; background: white; font-size: 20px; font-weight: 900"
              >
                {{ this.$vuetify.breakpoint.name }}
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-for="item in nav"
                :key="item.icon"
                to="#"
                :title="item.title"
                text
                :class="[menuItemsFont, 'btn-fix', 'toolbar-btn']"
                :color="item.active ? '#FF5800' : 'white'"
                :style="{
                  'text-transform': 'unset !important',
                }"
                active-class="no-active"
                @click="onItemClick(item)"
                >{{ item.text }}</v-btn
              >
              
              <a :href="twitter" target="_blank">
                <v-img height="16px" :src="require('@/assets/img/nav/twitter.svg')" contain class="px-3" />
              </a>
              <a :href="telegram" target="_blank">
                <v-img height="16px" :src="require('@/assets/img/nav/telegram.svg')" contain class="px-4" />
              </a>
              <a :href="discord" target="_blank">
                <v-img height="16px" :src="require('@/assets/img/nav/discord.svg')" contain class="px-4" />
              </a>
            </v-toolbar-items>
          </v-col>
          <v-col v-else align="end" cols="8">
            <div
              v-if="debugbreakpoint"
              style="color: green; background: white; font-size: 20px; font-weight: 900"
            >
              {{ this.$vuetify.breakpoint.name }}
            </div>
            <v-app-bar-nav-icon
              @click="drawer = true"
              :style="{
                color: 'white',
              }"
            ></v-app-bar-nav-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app bottom temporary hide-overlay>
      <v-list nav flat>
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in nav"
            :key="item.icon"
            @click="onItemClick(item)"
            class="mobile-nav-item"
            v-ripple="{ class: `red--text` }"
          >
            <v-list-item-title
              class="poppins-semi-bold-blue-gem-16px"
              :style="{
                color: item.active ? '#FF5800 !important' : '#310293 !important',
              }"
              >{{ item.text }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item class="mobile-nav-item">
            <v-spacer></v-spacer>
            <v-list-item-avatar class="mr-0">
              <v-img height="20px" :src="require('@/assets/img/nav/mobile/twitter.svg')" contain class="mr-0" />
            </v-list-item-avatar>
            <v-list-item-content class="poppins-semi-bold-blue-gem-16px" style="flex: none;">
              <a :href="twitter" target="_blank" style="text-decoration: none;">Twitter</a>
            </v-list-item-content>
            
          </v-list-item>
          <v-list-item class="mobile-nav-item">
            <v-spacer></v-spacer>
            <v-list-item-avatar class="mr-0">
              <v-img height="20px" :src="require('@/assets/img/nav/mobile/telegram.svg')" contain class="mr-0" />
            </v-list-item-avatar>
            <v-list-item-content class="poppins-semi-bold-blue-gem-16px" style="flex: none;">
              <a :href="telegram" target="_blank" style="text-decoration: none;">Telegram</a>
            </v-list-item-content>
            
          </v-list-item>
          <v-list-item class="mobile-nav-item">
            <v-spacer></v-spacer>
            <v-list-item-avatar class="mr-0">
              <v-img height="20px" :src="require('@/assets/img/nav/mobile/discord.svg')" contain class="mr-0" />
            </v-list-item-avatar>
            <v-list-item-content class="poppins-semi-bold-blue-gem-16px" style="flex: none;">
              <a :href="discord" target="_blank" style="text-decoration: none;">Discord</a>
            </v-list-item-content>
            
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-snackbar vertical bottom v-model="liveSnackbar" color="#20015e" timeout="-1" style="z-index: 100;">
          <div class="poppins-bold-white-16px ms-2 mb-2">
            Injective Grant DAO Quadratic Funding voting in progress!
          </div>
          <div style="position: relative; width: 100%">
            <CountdownTimer :until="injectiveProjectReviewDateTime" style="float: left;" />
            <div style="float: right;" class="mt-3">
              <v-btn color="#ff6200" text @click="liveSnackbar = false"  style="display: inline;">
                Close
              </v-btn>
              <a href="https://dorahacks.io/injective/1/buidl" target="_blank">
                <v-btn color="#ff6200" rounded class="mx-2">
                  Vote for us!
                </v-btn>
              </a>
            </div>
          </div>
        </v-snackbar>
  </v-layout>
</template>

<script>
import Vue from "vue";
import { mapActions } from "pinia";
import { useScrollStore } from "@/stores/scroll";
import CountdownTimer from "./CountdownTimer.vue";

export default {
  name: "NavBar",
  props: ["isTranslucent", "twitter", "telegram", "discord"],
  components: { CountdownTimer },
  computed: {
    appBarLogoClass() {
      if (this.$isMobile()) {
        return "app-bar-logo-mobile";
      } else {
        return "app-bar-logo-not-mobile";
      }
    },
    menuItemsFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          if (this.isTranslucent) {
            return "poppins-semi-bold-white-18px";
          } else {
            return "poppins-semi-bold-blue-gem-18px";
          }
        case "md":
          if (this.isTranslucent) {
            return "poppins-semi-bold-white-15px";
          } else {
            return "poppins-semi-bold-blue-gem-15px";
          }
        case "sm":
        case "xs":
          if (this.isTranslucent) {
            return "poppins-semi-bold-white-12px";
          } else {
            return "poppins-semi-bold-blue-gem-12px";
          }
      }
    },
  },
  data() {
    let navItems = [
      {
        icon: "agent",
        text: "Community",
        title: "Community",
        active: false,
        route: "/community",
      },
      {
        icon: "platform",
        text: "Platform",
        title: "Platform",
        active: false,
        route: "/platform",
      },
      {
        icon: "info",
        text: "About Us",
        title: "About Us",
        active: false,
        route: "/aboutus",
      },
      {
        icon: "whitepaper",
        text: "Whitepaper",
        title: "Whitepaper",
        active: false,
        route: "https://docs.timeworx.io",
        external: true,
      },
      {
        icon: "aidrops",
        text: "Airdrops",
        title: "Airdrops",
        active: false,
        route: "https://airdrops.timeworx.io",
        external: true,
      },
      {
        icon: "roadmap",
        text: "Roadmap",
        title: "Roadmap",
        active: false,
        route: "/roadmap",
      },
     {
       icon: "email",
       text: "Tokenomics",
       title: "Tokenomics",
       active: false,
       route: "/tokenomics",
     },
      {
        icon: "blog",
        text: "Blog",
        title: "Blog",
        active: false,
        route: "/blog",
      },
    ];
    return {
      injectiveProjectReviewDateTime: new Date(Date.UTC(2024, 3, 8, 13, 0, 0)),
      liveSnackbar: false,
      drawer: false,
      debugbreakpoint: false,
      nav: navItems,
    };
  },
  methods: {
    ...mapActions(useScrollStore, ["closeTop"]),
    onItemClick(item) {
      if (item.external) {
        window.open(item.route, "_blank");
      } else {
        this.markItemActive(item);
        window.scrollTo(0, 0);
        this.$router.push(item.route).catch((err) => {});
      }
    },
    onLogoClicked() {
      this.markAllItemsInactive();
      if (this.$router.history.current.path == "/") {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.closeTop();
        }, 500);
      } else {
        window.scrollTo(0, 0);
        this.$router.push("/").catch((err) => {});
      }
    },
    markItemActive(item) {
      const idx = this.nav.indexOf(item);
      this.markAllItemsInactive();
      Vue.set(this.nav, idx, {
        ...item,
        active: true,
      });
    },
    markAllItemsInactive() {
      this.nav = this.nav.map((el) => {
        return { ...el, active: false };
      });
    },
  },
  mounted() {
    const curRoute = this.$router.history.current.path;
    const curItem = this.nav.find((el) => el.route == curRoute);
    this.markItemActive(curItem);
  },
};
</script>

<style>
.app-bar-logo-mobile {
  height: 80px;
  margin-top: 10px;
  object-fit: cover;
  object-position: center; 
}

.app-bar-logo-not-mobile {
  margin-top: 5px;
  height: 100px !important;
  width: 250px;
  object-position: center; 
}

.toolbar-btn {
  outline: none;
  box-shadow: none;
}

.mobile-nav-item > div {
  text-align: end;
  margin-right: 29px;
}

.mobile-nav-item:active > div {
  color: var(--menu-orange);
}
</style>
