<template>
  <Page :title="title" :top-image="topImage">
    <v-row class="reflective-card mt-10 mt-md-16 pa-5 pa-lg-10" no-gutters>
      <v-col class="ma-lg-10 px-lg-16">
        <div :class="headingFont" class="highlight mb-3 mb-lg-5">How is $TIX distributed?</div>
        <div :class="textFont" class="justified mt-lg-5 mb-3 mb-lg-10">{{ topContent }}</div>
        <div :class="labelFont">
          <span>Total supply </span><span class="highlight">500,000,000 $TIX</span>
        </div>
        <img :src="require('@/assets/img/tokenomics/banner.png')" class="mt-10" style="max-width: 100%;" />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-lg-16 mb-lg-5" style="width: 100%; overflow: hidden;">
      <v-col>
        <CategoriesSlider
              :items="tokenomicsCategoriesProps"
              :selectedItemIndex="selectedItemIndex"
              @selectedItem="onItemSelected"
              class="token-cat-slider mt-4"
              style="z-index: 1;"
            />
      </v-col>
    </v-row>
    <v-row class="reflective-card mt-lg-8 mb-lg-12" justify="center" no-gutters>
      <v-col class="token-categories-col-bg" lg="10" md="11" cols="12">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="desktop-wrapper">
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-wrap justify-space-between mt-16">
                <div
                  class="d-flex flex-wrap justify-lg-start justify-center align-start"
                >
                  <div class="d-flex flex-column" style="width: 100%">
                    <div class="token-selected-cat-label mr-8 mb-4" :class="categoryFont">
                      {{ selectedItem.label }}
                    </div>
                    <div :class="headingFont">
                      {{ selectedItem.value }}
                    </div>
                    <div
                      style="height: 1px; background-color: #00ffff"
                      class="mt-4 mr-lg-n16"
                    ></div>
                    <div class="justified mt-4 mr-8" :class="textFont">
                      {{ selectedItem.description }}
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="5">
              <v-chart ref="chart" class="chart" :option="option" autoresize @selectchanged="onSelectChanged" />
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div class="chart mt-n16" style="position: relative;">
              <v-chart ref="chart" class="chart" :option="option" autoresize style="position: absolute; left: 0" @selectchanged="onSelectChanged" />
          </div>
          
          <div class="d-flex flex-wrap justify-space-between mx-lg-16 mx-10 mb-16 mt-n16">
            <div
              class="d-flex flex-wrap justify-lg-start justify-center align-start"
              :style="{ 'max-width': rightColMaxWidth }"
            >
              <div class="d-flex flex-column" style="width: 100%">
                <div class="token-selected-cat-label mr-8" :class="categoryFont">
                  {{ selectedItem.label }}
                </div>
                <div :class="headingFont">
                  {{ selectedItem.value }}
                </div>
                <div
                  style="height: 1px; background-color: #00ffff"
                  class="mt-4 mr-lg-n16"
                ></div>
                <div class="justified mt-4 mr-8" :class="textFont">
                  {{ selectedItem.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";
import Categories from "./Categories.vue";
import CircularProgress from "../CircularProgress.vue";
import CategoriesSlider from "./CategoriesSlider.vue";

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, defineComponent } from 'vue';

use([
  CanvasRenderer,
  PieChart,
  TooltipComponent,
]);

export default {
  name: "Tokenomics",
  components: { Page, Categories, CircularProgress, CategoriesSlider, VChart },
  props: [
    "title",
    "topImage",
    "topContent",
    "tokenomicsCategoriesProps",
  ],
  data() {
    return {
      selectedItem: this.tokenomicsCategoriesProps[0],
      selectedItemIndex: 0,
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : ({d}%)',
          extraCssText: 'font-family: var(--font-family-poppins); font-weight: 700; border-width: 4px;'
        },
        series: [
          {
            type: 'pie',
            selectedMode: 'single',
            selectedOffset: 5,
            radius: ['40%', '70%'],
            padAngle: 2,
            itemStyle: {
              borderRadius: 10
            },
            label: {
              show: false,
              position: 'center',
            },
            select: {
              label: {
                show: true,
                fontSize: this.$isMobile() ? 48 : 64,
                fontWeight: 400,
                fontFamily: '"Poppins", Helvetica',
                formatter: '{d}%'
              },
              itemStyle: {
                borderColor: '#00ffff',
                borderWidth: 2,
              },
            },
            emphasis: {
              scale: true,
              scaleSize: 10,
              label: {
                show: false,
                fontSize: this.$isMobile() ? 48 : 64,
                fontWeight: 400,
                fontFamily: '"Poppins", Helvetica',
                formatter: '{d}%'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: false
            },
            startAngle: 110,
            data: this.tokenomicsCategoriesProps.map((category) =>
              ({
                value: category.percentage,
                name: category.label,
                label: { color: category.color },
                selected: category.order == 0
              })
              ),
            color: this.tokenomicsCategoriesProps.map((category) => category.color),
          },
          {
            type: 'pie',
            radius: ['75%', '85%'],
            padAngle: 2,
            itemStyle: {
              borderRadius: 10
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                return `Community<hr />${params.name}: ${params.data.value}%`;
              }
            },
            startAngle: 110,
            endAngle: 110 - (360 * .33),
            data: [
              {
                value: 15,
                name: 'Staking Rewards',
              },
              {
                value: 15,
                name: 'Airdrops'
              },
              {
                value: 3,
                name: 'Community Projects'
              },
            ],
            color: [
              '#7B63DFBB',
            ]
          },
          {
            type: 'pie',
            radius: ['75%', '85%'],
            padAngle: 2,
            itemStyle: {
              borderRadius: 10
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                return `Growth<hr />${params.name}: ${params.data.value}%`;
              }
            },
            startAngle: 110 - (360 * .33),
            endAngle: 110 - (360 * .61),
            data: [
              {
                value: 10,
                name: 'Liquidity Reserve',
              },
              {
                value: 10,
                name: 'Company Reserve'
              },
              {
                value: 8,
                name: 'Marketing'
              },
            ],
            color: [
            '#D554FFBB',
            ]
          },
        ],
      },
    };
  },
  computed: {
    rightColMaxWidth() {
      // This makes sure on big screens the right column stays
      // on the same row and on smaller ones it moves to the next one
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "55%";
        default:
          return "100%";
      }
    },
    headingFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-42px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-26px";
          } else {
            return "poppins-bold-white-24px";
          }
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    textFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-14px";
          } else {
            return "poppins-normal-white-12px";
          }
        case "xs":
          return "poppins-normal-white-12px";
      }
    },
    categoryFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-48px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-36px";
          } else {
            return "poppins-bold-white-32px";
          }
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    labelFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-64px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-24px";
          } else {
            return "poppins-bold-white-20px";
          }
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
  },
  methods: {
    onItemSelected(item) {
      this.selectedItem = item;
      this.selectedItemIndex = this.tokenomicsCategoriesProps.indexOf(item);
      this.$refs.chart.dispatchAction(
        {
          type: 'select',
          dataIndex: this.selectedItemIndex,
        })
    },
    onSelectChanged(params) {
      if (params.fromActionPayload && params.fromActionPayload.seriesIndex > 0) {
        return ;
      }

      if (params.fromAction == "select") {
        if (params.isFromClick) {
          this.selectedItemIndex = params.fromActionPayload.dataIndexInside;
        } else {
          this.selectedItemIndex = params.fromActionPayload.dataIndex;
        }
        this.selectedItem = this.tokenomicsCategoriesProps[this.selectedItemIndex];
      } else if (params.fromAction == "unselect") {
        this.onItemSelected(this.selectedItem);
      }
    },
  },
};
</script>

<style>

.highlight {
  color: #D554FF !important;
}

.token-selected-cat-label {
  color: #00ffff !important;
}

.chart {
  height: 500px;
}

.desktop-wrapper {
  padding: 25px 140px 50px;
}

.justified {
  text-align: justify;
  text-justify: inter-word;
}
</style>
