<template>
  <Page class="blog-bg">
    <v-container class="blog mt-10 px-1">
      <h1 :class="titleFont"><span v-if="article.draft" :class="authorFont" class="draft-label">Draft</span> {{ article.title }}</h1>
      <v-row no-gutters class="flex-nowrap mt-3 mt-md-6">
        <v-col class="flex-grow-0 flex-shrink-1">
          <img :height="authorImageSize" :src="article.author.image" />
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 align-self-center ml-3 ml-md-5">
          <v-row no-gutters :class="authorFont">{{ article.author.name }}</v-row>
          <v-row no-gutters class="poppins-normal-white-14px label-grey">
            {{ `${article.date} &centerdot; ${article.time} min read` }}
          </v-row>
        </v-col>
      </v-row>
      <img :src="article.featuredImage" class="my-3 my-md-6" style="max-width: 100%;" />
      <v-row no-gutters class="ma-0">
        <v-col class="flex-shrink-1 flex-grow-0 poppins-normal-white-14px mr-2 label-grey align-self-center" cols="auto">
          Share on:
        </v-col>
        <v-col class="flex-shrink-1 flex-grow-0 mx-3 align-self-center" :style="{ height: shareIconSize }">
          <a target="_blank" rel="noopener noreferrer" @click="event => shareOnLinkedIn(event)">
            <img :height="shareIconSize" :width="shareIconSize" :src="require('@/assets/img/blog/linkedin.svg')" />
          </a>
        </v-col>
        <v-col no-gutters class="flex-shrink-1 flex-grow-0 mx-3 align-self-center" :style="{ height: shareIconSize }">
          <a target="_blank" rel="noopener noreferrer" @click="event => shareOnTwitter(event)">
            <img :height="shareIconSize" :width="shareIconSize" :src="require('@/assets/img/blog/twitter.svg')" />
          </a>
        </v-col>
        <v-col class="flex-shrink-1 flex-grow-0 mx-3 align-self-center" :style="{ height: shareIconSize }">
          <a target="_blank" rel="noopener noreferrer" @click="event => copyToClipboard(event)">
            <img :height="shareIconSize" :width="shareIconSize" :src="require('@/assets/img/blog/clipboard.svg')" />
          </a>
        </v-col>
        <v-col class="flex-shrink-1 flex-grow-0 mx-3 align-self-center label-separator-vertical"
          v-if="$vuetify.breakpoint.lgAndUp"></v-col>
        <v-col class="flex-shrink-1 flex-grow-0 my-3" cols="12" v-else>
          <div class="label-separator-horizontal"></div>
        </v-col>
        <v-col class="flex-shrink-0 flex-grow-1 align-self-center" cols="12" md="auto">
          <v-chip outlined color="#34323A" text-color="#B09EFF" style="border-color: #B09EFF" :class="labelFont"
            class="article-label mr-3" v-for="label in article.labels">
            {{ label }}
          </v-chip>
        </v-col>
      </v-row>
      <div class="toc" :class="tocSpacing">
        <h2 :class="headingFont" class="pb-3 pb-md-0">In this article</h2>
        <ol class="toc-list mt-n3 mt-md-0">
          <li v-for="section in article.toc" class="toc-entry" :class="tocEntryFont">
            <a :href="section.href">{{ section.label }}</a>
          </li>
        </ol>
      </div>
      <div :class="contentClass" v-html="article.content"></div>
      <v-snackbar v-model="snackbar" timeout="2000">
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="#ff6200" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </Page>
</template>

<script>
import Page from "../Page.vue";
import { getFirestore, getDocs, collection, query, where } from "firebase/firestore";

export default {
  name: "BlogArticle",
  components: { Page },
  props: [],
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      article: {
        author: {},
      },
    };
  },
  metaInfo() {
    return {
      title: this.article.title,
      meta: [
        { "og:title": this.article.title },
        { "og:image": this.article.featuredImage },
        { "og:description": this.article.intro },
        { "og:author": this.article.author.name }
      ]
    }
  },
  async mounted() {
    const tag = Object.keys(this.$route.query)[0];
    if (tag === undefined) {
      this.$router.push("blog");
    } else {
      this.runQuery(tag);
    }
  },
  computed: {
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-40px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    headingFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-24px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-16px";
      }
    },
    contentClass() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "content";
        case "md":
        case "sm":
        case "xs":
          return "content-mobile";
      }
    },
    tocEntryFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-white-14px";
      }
    },
    tocSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "toc-spacing";
        case "md":
        case "sm":
        case "xs":
          return "toc-spacing-mobile";
      }
    },
    authorFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-16px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-white-14px";
      }
    },
    labelFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-14px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-normal-12px";
      }
    },
    authorImageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "64";
        case "md":
        case "sm":
        case "xs":
          return "48";
      }
    },
    shareIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "24";
        case "md":
        case "sm":
        case "xs":
          return "20";
      }
    },
    currentUrl() {
      const route = this.$router.resolve(this.$route);
      return new URL(route.href, window.location.origin).href;
    },
  },
  methods: {
    async runQuery(tag) {
      const q = query(collection(getFirestore(), "blog"), where("tag", "==", tag));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        this.$router.push("blog");
      } else {
        let data = querySnapshot.docs[0].data();
        this.article = {
          author: {
            name: data.author.name,
            image: data.author.img_src,
          },
          content: data.content,
          date: this.formatDate(data.date.toDate()),
          draft: data.draft,
          featuredImage: data.featured_large_img_src,
          intro: data.intro,
          time: data.reading_time_minutes,
          title: data.title,
          toc: data.toc,
          labels: data.topics,
        };
      }
    },
    formatDate(date) {
      const month = date.toLocaleString('default', { month: 'short' });
      return `${month} ${date.getDate()}, ${date.getFullYear()}`;
    },
    copyToClipboard(event) {
      event.preventDefault();
      navigator.clipboard.writeText(this.currentUrl).then(() => {
        if (!this.$isMobile()) {
          this.snackbarMessage = `Copied ${this.currentUrl} to your clipboard`;
          this.snackbar = true;
        }
      }, () => {
        this.snackbarMessage = "Failed to copy to clipboard.";
        this.snackbar = true;
      });
    },
    shareOnLinkedIn(event) {
      event.preventDefault();
      const title = this.article.title;
      const link = this.$isMobile() ? `https://www.linkedin.com/shareArticle?mini=true&url=${this.currentUrl}`
        : `https://www.linkedin.com/sharing/share-offsite/?url=${this.currentUrl}`;
      window.open(link, '_blank', 'noreferrer');
    },
    shareOnTwitter(event) {
      event.preventDefault();
      const text = this.article.title;
      const tags = this.article.labels.map(label => label.replace(/ /g, '')).toString();
      const link = this.$isMobile() ? `https://twitter.com/intent/tweet?text=${text}&url=${this.currentUrl}&hashtags=${tags}`
        : `https://twitter.com/share?text=${text}&url=${this.currentUrl}&hashtags=${tags}`;
      window.open(link, '_blank', 'noreferrer');
    }
  },
};
</script>

<style scoped>
.blog-bg {
  background: black;
}

.blog {
  max-width: 1280px;
}

.toc {
  background: #242326;
  border-radius: 12px;
  width: 100%;
}

.toc-spacing {
  margin: 24px 0px;
  padding: 36px;
}

.toc-spacing-mobile {
  margin: 12px 0px;
  padding: 16px;
}

.toc-entry {
  margin-top: 10px;
}

ol.toc-list {
  list-style: decimal;
  margin-left: -12px;
}

ol.toc-list li::before {
  color: white;
  display: inline-block;
  width: 1em;
}

.label-grey {
  color: #ECECEC;
}

.label-separator-vertical {
  width: 0px;
  height: 40px;

  border-right: 1px solid #ECECEC;
}

.label-separator-horizontal {
  width: 192px;
  height: 0px;
  border: 1px solid #38363A;
}

.article-label.v-chip.v-chip--outlined.v-chip.v-chip {
  background: #34323A !important;
}

.article-label.v-chip.v-chip--outlined.v-chip.v-chip--active {
  background: #E6DEFF !important;
  color: #310293 !important;
}

.draft-label {
  color: var(--accent);
  border: thin var(--accent) solid;
  border-radius: 5px;
  padding: 5px;
}

.content>>>p {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
}

.content-mobile>>>p {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
}

.content>>>h2 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.content-mobile>>>h2 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 12px;
}

.content>>>h3 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 16px;
}

.content-mobile>>>h3 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 8px;
}

* a {
  color: inherit !important;
}

.content>>>a {
  color: #B09EFF !important;
}

.content-mobile>>>a {
  color: #B09EFF !important;
}

.content>>>ul {
  list-style-type: disc;
}

.content-mobile>>>ul {
  list-style-type: disc;
}

.content>>>ul>li {
  color: white;
}

.content-mobile>>>ul>li {
  color: white;
}
</style>
