<template>
  <Page :title="title" class="install-bg">
    <v-row class="reflective-card" justify="center" style="width: 100%;">
      <v-col
        lg="9"
        cols="11"
        class="my-md-10"
      >
        <v-row justify="center" class="py-10">
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">Android</div>
            <div :class="contentFont" class="justified mt-10">
              <ol class="ml-n5">
                <li>Install <a href="https://play.google.com/store/apps/details?id=io.timeworx.agent" target="_blank">Timeworx.io</a> from the Play Store</li>
              </ol>
            </div>
          </v-col>
          <v-col md="1" cols="0" />
          <v-col md="5" cols="11" class="d-flex flex-column">
            <div :class="titleFont" class="align-self-center align-self-md-start">iOS</div>
            <div :class="contentFont" class="justified mt-10">
              <ol class="ml-n5">
                <li>Install <a href="https://testflight.apple.com/join/JTzlNn5X" target="_blank">Timeworx.io.</a> from TestFlight</li>
              </ol>
              <p class="mt-8">If you are asked for an invitation code, please use <span style="font-family: monospace;">JTzlNn5X</span></p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "InstallMobile",
  metaInfo: {
    title: "How to install the mobile apps"
  },
  data() {
    return {
      title: "How to install the mobile apps",
    };
  },
  components: { Page },
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-50px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-white-34px";
          } else {
            return "poppins-bold-white-30px";
          }
        case "xs":
          return "poppins-bold-white-25px";
      }
    },
  },
};
</script>

<style scoped>
a:link { text-decoration: none; color: var(--accent); }
a:visited { text-decoration: none; color: var(--accent); }
a:hover { text-decoration: none; color: var(--accent); }
a:active { text-decoration: none; color: var(--accent); }

.justified {
  text-align: justify;
  text-justify: inter-word;
}

ol {
  list-style: none;
  counter-reset: item;
}

li {
  margin-top: 10px;
  counter-increment: item;
}

li:before {
   margin-right: 15px;
   content: counter(item);
   background: var(--accent);
   border-radius: 100%;
   color: white;
   width: 1.5em;
   text-align: center;
   display: inline-block;
 }
</style>
