<template>
  <Page title="Artwork Competition Regulation">
    <v-row no-gutters justify="center" class="mb-16">
      <v-col no-gutters>
        <h2 :class="[subtitleFont, xSpacing]">Art. 1 - Organizer</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">

          <p>
            Timeworx.io is the organizer of the Brand Artwork Competition. The Organizer reserves the right to amend
            this Regulation during the Competition, as well as the right to suspend and/or terminate and/or interrupt
            and/or extend the Competition, with such amendments coming into force at the time of their communication to
            the participants by publishing on the Timeworx.io dedicated <a :href="channel" target="_blank"
              rel="noreferrer">Discord competition channel</a>.
          </p>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">Art. 2 - Duration and venue of the Campaign</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            The Competition is organized and will run between <span class="bold">03/25/2025 - 04/18/2025</span>, on X
            (Twitter) and Timeworx.io Discord server, as follows:
          </p>

          <ul class="priv-list">
            <li>03/25/2025 - 04/10/2025 (17 days) - Artworks registration and Voting</li>
            <li>03/25/2025 - 04/17/2025 (22 days) - Community Voting</li>
            <li>04/11/2025 - 04/18/2025 (7 days) - Evaluation of votes & artworks</li>
            <li>04/18/2025 - Winners final decision & announcement</li>
          </ul>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">Art. 3 - Competition Mechanism</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            Any X (Twitter) and Discord user can participate in the Competition if he/ she meets the mandatory terms
            below:
          </p>
          <ul class="priv-list">
            <li>Create an X (Twitter) post with text + image or video/ animation using our media kit on the topic
              “Timeworx.io: The Data Processing Layer for Next Gen AI”</li>
            <li>Post it publicaly on your X (Twitter) profile and tag @timeworx_io. <span class="bold">Important</span>:
              keep your profile public for the Organizer to be able to check & validate your artwork & registration.
            </li>
            <li>Copy & paste the link of your X (Twitter) post on this dedicated Discord competition channel: <a :href="channel"
                target="_blank" rel="noreferrer">#brand-artwork-competition</a></li>
            <li>Follow @timeworx_io on X (Twitter).</li>
          </ul>
          <br />
          <p>
            One person/ account can register several artworks. Each different registration is a chance to win one of the
            prizes.
          </p>
        </div>

        <h2 :class="[subtitleFont, xSpacing]">Art. 4 - Prizes and Winners voting mechanism</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            The Competition will have two prizes and two winners, which will be decided as follows:
          </p>
          <ul class="priv-list">
            <li>One Timeworx.io team grand prize: <span class="bold">$500</span><br /><br />Timeworx.io team will review
              all of the registrations that respect the participation conditions and will decide on the most accurate,
              complex, and creative brand artwork, considering the competition topic: <span class="bold">Timeworx.io -
                The Data Processing Layer for Next Gen AI</span>.<br /><br /></li>
            <li>One Early Adopters Community prize: <span class="bold">$250</span><br /><br />Voting will take place on
              the Timeworx.io Discord server as follows:<br /><br />
              <ol class="priv-list">
                <li>Users will copy-paste their artworks X posts links to the dedicated Discord competition channel: <a :href="channel"
                  target="_blank" rel="noreferrer">#brand-artwork-competition</a></li>
                <li>Every valid message will be automatically forwarded to the dedicated Discord voting channel: <a href="https://discord.gg/wgeytgWb"
                    target="_blank" rel="noreferrer">#brand-artwork-voting</a>, where only early adopters have access
                  and can vote for their favorite artwork(s): 1 vote = 1 emoji reaction on Discord to their favorite artwork post.
                </li>
              </ol><br />Early adopters are Discord users having the “Early Adopter” role on the Timeworx.io
              Discord server.<br /><br />A single vote per artwork will be taken into consideration for each voter.
              Voters can vote for several artwork posts.<br /><br />After the end of the voting period (04/17/2025,
              23:59 EET/ 21:59 UCT), the voters (early adopters) will not be able to vote anymore.<br /><br />
            </li>
          </ul>
          <br />
        </div>

        <h2 :class="[subtitleFont, xSpacing]">Art. 5 - Winners validation</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>
            The Organizer will check and only validate the registrations that adhere to the participation conditions:
          </p>

          <ul class="priv-list">
            <li>respected the competition topic: <span class="bold">Timeworx.io - The Data Processing Layer for Next Gen
                AI</span></li>
            <li>followed @timeworx_io on X (Twitter)</li>
            <li>posted the artwork on their X (Twitter) profile and tagged @timeworx_io</li>
            <li>has their X (Twitter) profile public so the team can check and validate their X (Twitter) artwork post
            </li>
            <li>copied & pasted the link of the X (Twitter) post with the artwork on this Timeworx's <a :href="channel"
                target="_blank" rel="noreferrer">Discord competition channel</a></li>
          </ul>

          <br />

          <p>
            The Organizer will check and validate the registrations during the registration and voting period, and as
            well as during the votes counting and decision period.
          </p>

          <p>
            The Organizer will not validate the registrations that can be considered false, misleading, rude, or
            offensive in any aspect.
          </p>

          <p>
            The Organizer has the right not to validate any registration if, at any point in the competition, it
            discovers the registration does not respect the conditions or it is suspected of fraudulent behavior.
          </p>

          <p>
            The winners will be announced by the Organizer in a public post on X (Twitter) official account - <a
              href="https://x.com/timeworx_io" target="_blank" rel="noreferrer">Timeworx.io (@timeworx_io) on X </a> -
            as well as through a private message on X (Twitter), on April 18th, end of the day at the latest.
          </p>

          <p>
            The winners have 10 working days time to reply to/ get in contact with Timeworx.io and claim the prize.
            Otherwise, the prize will be forfeited.
          </p>

        </div>

        <h2 :class="[subtitleFont, xSpacing]">
          Art. 6 - Right to participate
        </h2>

        <div :class="[contentFont, spacing, 'spacing-last']" class="reflective-card">
          <p>
            The Organizer reserves the right to cancel at its sole discretion the participation of any person who fails
            to comply with the Regulation Terms, who is suspected of fraud, who acts in an unethical or disruptive
            manner. Also, the Organizer will not validate the registrations that can be considered false, misleading,
            rude, or offensive in any aspect.
          </p>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "ArtworkCompetition",
  metaInfo: {
    title: "Artwork Competition"
  },
  components: { Page },
  props: [],
  data() {
    return {
      channel: "https://discord.gg/ZDp9QUua"
    };
  },
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    subtitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-42px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    headlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-64px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "spacing-desktop";
        case "sm":
        case "xs":
          return "spacing-mobile";
      }
    },
    xSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-spacing-desktop";
        case "sm":
        case "xs":
          return "x-spacing-mobile";
      }
    },
  },
};
</script>

<style scoped>
.priv-list {
  list-style-type: none;
}

.priv-list li:before {
  content: "\2022";
  padding-right: 0.5em;
  color: white;
}

.highlight {
  color: #D554FF;
}

.spacing-desktop {
  padding: 80px 160px;
  margin-bottom: 80px;
}

.spacing-mobile {
  padding: 20px;
  margin-bottom: 40px;
}

.spacing-last {
  margin-bottom: 0px !important;
}

.x-spacing-desktop {
  padding: 0px 160px;
  margin-bottom: 40px !important;
}

.x-spacing-mobile {
  padding: 0px 20px;
  margin-bottom: 20px !important;
}

* a {
  color: inherit !important;
}

.bold {
  font-weight: 700;
}
</style>
