<template>
  <v-card class="round">
    <v-container class="ma-0 pa-0">
      <v-row no-gutters justify="center">
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12" align="end">
          <v-btn icon class="pa-8" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" md="6" class="mt-md-10">
          <div :class="titleBoldFont" class="px-md-16" style="text-align: center;">Ongoing Reg D Token Offering with Republic</div>
          <div :class="titleFont" style="text-align: center;">Join the allowlist now 🚀</div>
          <div class="poppins-normal-on-surface-variant-14px allowlist-body mt-5 mt-md-10 mx-8 px-0 mx-md-6 px-md-8">Please fill out the following information:</div>

          <v-form ref="form" v-model="valid" lazy-validation class="mx-4 px-0 mx-md-6 px-md-8 mt-7">
            <div class="d-flex flex-column flex-md-row">
              <v-text-field
                label="First name"
                class="poppins-normal-on-surface-variant-14px"
                background-color="white"
                rounded
                outlined
                :rules="firstNameRules"
                required
                v-model="firstName"
              ></v-text-field>

              <v-text-field
                label="Last name"
                class="poppins-normal-on-surface-variant-14px pl-md-10"
                background-color="white"
                rounded
                outlined
                :rules="lastNameRules"
                required
                v-model="lastName"
              ></v-text-field>
            </div>

            <v-text-field
              label="Email address"
              class="poppins-normal-on-surface-variant-14px"
              background-color="white"
              rounded
              outlined
              :rules="emailRules"
              required
              v-model="email"
            ></v-text-field>
            
            <v-autocomplete
              label="Country of residence"
              class="poppins-normal-on-surface-variant-14px"
              :menu-props="{
                contentClass: 'poppins-normal-on-surface-variant-14px'
              }"
              background-color="white"
              rounded
              outlined
              :items="countries"
              :rules="countryRules"
              required
              v-model="country"
              :attach="true"
            ></v-autocomplete>

            <v-text-field
              label="Wallet address (inj or erd)"
              class="poppins-normal-on-surface-variant-14px"
              background-color="white"
              rounded
              outlined
              :rules="walletAddressRules"
              required
              v-model="address"
            ></v-text-field>

            <v-checkbox v-model="acceptPrivacyPolicy" class="allowlist-checkbox mt-n2">
              <template v-slot:label>
                <div>I have read and accept the
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        href="https://timeworx.io/privacy"
                        @click.stop
                        v-on="on"
                      >
                        Privacy Policy
                      </a>
                    </template>
                    Opens in new window
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>

            <v-checkbox v-model="acceptNonBinding" class="allowlist-checkbox mt-n4">
              <div slot='label'>I understand and accept that this registration form is non-binding</div>
            </v-checkbox>

            <v-checkbox v-model="acceptMarketing" class="allowlist-checkbox mt-n4">
              <div slot='label'>I agree to receive news, updates, promotional info and other marketing updates from Timeworx.io</div>
            </v-checkbox>
      
            <v-row justify="center" class="mb-8 mb-md-16">
              <v-col align="center">
                <v-btn
                    v-if="!loading"
                    :disabled="!acceptPrivacyPolicy || !acceptNonBinding"
                    elevation="2"
                    rounded
                    color="#3B00A0"
                    style="text-transform: unset !important"
                    @click="submit"
                    class="px-6 mt-n3 mt-md-0">
                      <span class="poppins-normal-white-14px">Register</span>
                  </v-btn>
                <v-progress-circular
                  indeterminate
                  color="#ff6200"
                  v-if="loading"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6">
          <v-img :src="require('@/assets/img/allowlist-banner.png')" style="height: 100%;"></v-img>
          <v-btn class="pa-8" @click="$emit('close')" fab icon top right style="position: absolute; top: 0; right: 0">
              <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" absolute>
        {{ snackbarMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="#ff6200"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
        </v-snackbar>
    </v-container>
  </v-card>
</template>

<script>
import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase/config';
import { getCountryDataList } from 'countries-list'
import Button from './Button.vue';
import { bech32 } from 'bech32'
const config = require("@/config");

export default {
  name: "AllowlistRegistration",
  components: {
    Button,
  },
  props: [],
  data() {
    return {
      countries: getCountryDataList().map((country) => country.name),
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      address: "",
      valid: true,
      acceptPrivacyPolicy: false,
      acceptNonBinding: false,
      acceptMarketing: false,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      firstNameRules: [
        v => !!v || 'First name is required',
      ],
      lastNameRules: [
        v => !!v || 'Last name is required',
      ],
      countryRules: [
        v => !!v || 'Country of residence is required',
      ],
      walletAddressRules:  [
        v => !!v || 'Wallet address is required',
        v => v.length < 3 || v.startsWith('inj') || v.startsWith('erd') || 'We only support Injective and MultiversX wallets',
        v => v.length < 3 || this.validateBech32(v) || 'We only support Injective and MultiversX wallets',
      ],
      snackbar: false,
      snackbarMessage: '',
      loading: false,
    };
  },
  computed: {
    titleBoldFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-blue-gem-32px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-bold-blue-gem-32px";
          } else {
            return "poppins-bold-blue-gem-24px";
          }
        case "xs":
          return "poppins-bold-blue-gem-24px";
      }
    },
    titleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-semi-bold-black-24px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-semi-bold-black-24px";
          } else {
            return "poppins-semi-bold-black-16px";
          }
        case "xs":
          return "poppins-semi-bold-black-16px";
      }
    },
    radioFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-on-surface-16px";
        default:
          return "poppins-normal-on-surface-16px";
      }
    },
  },
  methods: {
    submit () {
      this.$refs.form.validate();
      setTimeout(() => {
        if (this.valid) {
          this.loading = true;
          const allowlistRegistration = httpsCallable(functions, config.functions.ALLOWLIST_REGISTRATION);
          allowlistRegistration({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            country: this.country,
            address: this.address,
            allowMarketing: this.acceptMarketing,
          })
          .then((result) => {
            this.snackbarMessage = "You've successfully registered for the allowlist! Please check your email for more info";
            this.snackbar = true;
            this.loading = false;

            this.$gtm.trackEvent({
              event: 'register-allowlist',
              category: 'Allowlist',
              action: 'click',
              label: 'register-allowlist',
              value: 0,
              noninteraction: false, // Optional
            });

            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.country = "";
            this.address = "",
            this.acceptPrivacyPolicy = false;
            this.acceptNonBinding = false;
            this.acceptMarketing = false;
            this.$refs.form.reset();
          })
          .catch((error) => {
            console.log(error.code)
            console.log(error.message)
            console.log(error.details)
            this.snackbarMessage = "There was an error, please try again later.";
            this.snackbar = true;
            this.loading = false;
          });
        }
      });
    },
    validateBech32(address) {
      try {
        bech32.decode(address)
        return true
      } catch (error) {
        return false
      }
    },
  }
};
</script>

<style scoped>
.allowlist-body {
  display: flex;
  letter-spacing: 0.25px;
  white-space: pre-wrap;
}

.allowlist-checkbox .v-label {
  color: #79757F;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
} 

::v-deep .allowlist-checkbox .v-label {
  color: #79757F;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

::v-deep .v-input__slot {
  align-items: unset !important;
}

.round {
  max-width: 1200px;
  border-radius: 30px;
  overflow: hidden;
}

::v-deep .v-list-item__mask {
  color: #FF5800 !important;
  background-color: #d5ddeb !important;
  font-weight: 600 !important;
}
</style>
