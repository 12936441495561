<template>
  <Page :title="title" :topImage="topImage">
    <!-- Section 01 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">01</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our mission</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10 opaque" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont" class="highlight mb-5 mb-md-16">Bridge the gap between human and artificial intelligence towards solving the ground truth for AI</h1>
      <v-col cols="12" md="6" class="align-self-center">
        <div :class="normalTextFont" class="justified">We believe that data processing, Artificial Intelligence and Machine Learning are in dire need of democratisation and decentralisation. To address these challenges, we introduce Timeworx.io: a decentralized and scalable platform for companies to process their data easily and accurately by using a diversified and global workforce of agents, whether human or AI, incentivised through Blockchain technologies.</div>
        <div class="d-flex justify-center justify-md-start mt-6">
          <a href="https://docs.timeworx.io" target="_blank">
            <v-btn elevation="3" rounded :style="{
              'background-color': '#ff6200',
              'color': 'white',
              'z-index': 2,
              'text-transform': 'unset !important',
            }">
              <div class="poppins-semi-bold-white-14px">Read more in our whitepaper</div>
            </v-btn>
          </a>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="5" class="justify-space-around align-self-center">
        <v-img
          class="mt-10 mt-md-0"
          :src="require('@/assets/img/aboutus/mission.png')"
          max-width="100%"
          contain>
        </v-img>
      </v-col>
    </v-row>

    <!-- Section 02 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">02</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our vision</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10 opaque" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <h1 :class="sectionHeadlineFont" class="mb-md-16">Create a space where everyone can contribute to a more ethical, open and mutually equitable future of AI development guided by three objectives</h1>
      <div class="d-flex flex-md-row flex-column justify-md-space-between my-10">
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">AI that is fair</h2>
          <div :class="smallTextFont" class="justified">ML models that are trained on non-diverse and biased data lead to  allocative and representational harms which, in turn, make predictions that disadvantage people and tarnish the image of AI. At Timeworx.io, the approach to data labeling is set to be distinctly different, ethical and innovative. The focus is on creating a decentralized protocol based on openness, diversity, transparency, accountability and mutual equitability. Instead of pushing 8+ hours per day for hundreds of people, we’re more about getting 15+ minutes per day from millions of people worldwide.</div>
        </div>
        <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
        <div class="horizontal-divider d-md-none my-5"></div>
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">AI that is privacy-enhancing</h2>
          <div :class="smallTextFont" class="justified">Data, especially when sourced from users or sensitive sectors, needs to be treated with utmost confidentiality. Ensuring data privacy during the labeling process becomes an imperative. With its foundation on the blockchain, data integrity and security are paramount in Timeworx.io. Furthermore, we are taking one step further with the integration of federated learning. In a completely decentralized manner, data never leaves the user’s phone, rather the ML model is downloaded, trained, and uploaded without leaving any trace of the data it was trained on.</div>
        </div>
        <div class="vertical-divider flex-grow-1 flex-shrink-0 mx-4 mx-lg-16"></div>
        <div class="horizontal-divider d-md-none my-5"></div>
        <div class="d-flex flex-column wider">
          <h2 :class="emphasisTextFont" class="emphasis mb-3 mb-md-6">AI that is trusted</h2>
          <div :class="smallTextFont" class="justified">The AI landscape is dominated by large tech companies which drive innovation and dictate the tone. Without any formal training in the ML field, most of society is looking up at these key players on the market with awe and appreciation based solely on their influence and visibility. Timeworx.io takes a different, trustless, approach in which no AI (or human for that matter) is considered to be trusted. Our innovative decentralized protocol ensures that the ground truth in data processing is determined through Consensus, and that performance is accounted for using on-chain proofs.</div>
        </div>
      </div>
      <div class="wider d-flex justify-center">
        <a href="https://docs.timeworx.io" target="_blank">
          <v-btn elevation="3" rounded :style="{
            'background-color': '#ff6200',
            'color': 'white',
            'z-index': 2,
            'text-transform': 'unset !important',
          }">
            <div class="poppins-semi-bold-white-14px">Read more in our whitepaper</div>
          </v-btn>
        </a>
      </div>
    </v-row>

    <!-- Section 03 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">03</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our guiding principles</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10 opaque" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <v-col cols="12" md="6" class="pr-md-10 pb-md-10 pt-10 pt-md-0">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/decentralization.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Decentralization</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">Through Blockchain technologies, Timeworx.io ensures a distributed workforce of actors across the data value chain, incentivised transparently and fairly, promotes merit-based compensation, and motivates all of the actors towards providing accurate results.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pl-md-10 pb-md-10 pt-10 pt-md-0">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/openness.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Openness</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">The platform democratises data processing by employing all actors in the data value chain. It seamlessly integrates with advanced AI and ML models, ensuring that the protocol can make use of both automated and manual data processing effectively.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pr-md-10 py-md-10">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/diversification.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Diversification</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">The decentralised nature of Timeworx.io implies that the data is processed by a diverse set of actors in the data value chain coming from different backgrounds, geographies, and expertise levels. This minimises biases and provides a richer set of results.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pl-md-10 py-md-10">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/scalability.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Scalability</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">By leveraging a decentralised workforce of actors from the data value chain, the platform provides scalability seamlessly. No matter the volume of data, the platform can tap into a vast pool of data processing actors to get the job done efficiently.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pr-md-10 py-md-10">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/fairness.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Fairness</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">Timeworx.io does not enforce pricing, rather it employs a decentralised and dynamic pricing model that empowers companies and agents through a flexible and responsive pricing mechanism.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pl-md-10 py-md-10">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/effectiveness.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Cost-Effectiveness</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">By decentralising the data processing workforce and integrating AI automation, the platform offers competitive pricing. This ensures businesses can process their data without breaking the bank.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pr-md-10 pt-md-10 pb-10 pb-md-0">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/decentralization.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Accountability</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">Timeworx.io employs robust quality control mechanisms as on-chain proofs of performance for data processing actors. Constant performance validation and feedback loops ensure that the processed data is of the highest quality.</div>
      </v-col>
      <div class="horizontal-divider d-md-none my-10"></div>
      <v-col cols="12" md="6" class="pl-md-10 pt-md-10 pb-10 pb-md-0">
        <div class="d-flex flex-row align-center">
          <v-img :src="require('@/assets/img/aboutus/icons/trustlessness.svg')" :max-width="iconSize" :max-height="iconSize"></v-img>
          <h1 :class="sectionHeadlineFont" class="highlight ml-3 ml-md-5">Trustlessness</h1>
        </div>
        <div :class="normalTextFont" class="mt-3 mt-md-5 justified">All actors in the data value chain enrolled in the platform participate in achieving consensus for determining the ground truth for data processing. There is no underlying authority that needs to be trusted, nor do the actors require to know or trust each other.</div>
      </v-col>
      <div class="wider d-flex justify-center my-6">
        <a href="https://docs.timeworx.io/the-solution/principles" target="_blank">
          <v-btn elevation="3" rounded :style="{
            'background-color': '#ff6200',
            'color': 'white',
            'z-index': 2,
            'text-transform': 'unset !important',
          }">
            <div class="poppins-semi-bold-white-14px">Read more in our whitepaper</div>
          </v-btn>
        </a>
      </div>
    </v-row>

    <!-- We are personas -->
     <div class="opaque my-16" style="min-width: 100vw !important;" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <vue-marquee-slider
        id="personas1"
        autoWidth
        :speed="$isMobile() ? 10000 : 18000"
        :space="$isMobile() ? 12 : 40"
      >
        <div class="persona px-3 py-2 px-md-12 py-md-10"
          :class="[personaTextFont, index % 2 ? 'bold' : 'slim']"
          v-for="(persona, index) in weAre1" :key="index"
        >
          {{ persona }}
        </div>
      </vue-marquee-slider>
      <vue-marquee-slider
        id="personas2"
        class="my-6 my-md-10"
        autoWidth
        reverse
        :speed="$isMobile() ? 10000 : 18000"
        :space="$isMobile() ? 12 : 40"
      >
      <div class="persona px-3 py-2 px-md-12 py-md-10"
          :class="[personaTextFont, index % 2 ? 'bold' : 'slim']"
          v-for="(persona, index) in weAre2" :key="index"
        >
          {{ persona }}
        </div>
      </vue-marquee-slider>
      <vue-marquee-slider
        id="personas3"
        autoWidth
        :speed="$isMobile() ? 10000 : 18000"
        :space="$isMobile() ? 12 : 40"
      >
      <div class="persona px-3 py-2 px-md-12 py-md-10"
          :class="[personaTextFont, index % 2 ? 'bold' : 'slim']"
          v-for="(persona, index) in weAre3" :key="index"
        >
          {{ persona }}
        </div>
      </vue-marquee-slider>
     </div>

     <!-- Section 04 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">04</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our team</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card shadow-container wider mt-5 mt-md-10 opaque" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <div class="shadow" style="font-size: 36cqw;">Team</div>
      <v-slide-group :class="padding" class="px-0" show-arrows="false">
        <template v-slot:next>
          <v-icon color="#00FFFF" large>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:prev>
          <v-icon color="#00FFFF" large>mdi-chevron-left</v-icon>
        </template>

        <v-slide-item class="wider" v-for="member in team">
          <a :href="member.linkedin ?? member.wikipedia" target="_blank" rel="noopener noreferrer">
            <v-col no-gutters>
              <v-img :src="member.img" :width="teamPicSize" />
              <div class="poppins-normal-white-16px bold centered mt-6">{{ member.name }}</div>
              <div class="poppins-normal-white-16px secondary-text centered mt-2">{{ member.job }}</div>
              <v-img
                class="mt-2 mt-md-4"
                :src="require('@/assets/img/socialmedia/linkedin.svg')"
                max-width="40px"
                style="
                  margin-left: auto;
                  margin-right: auto;
                "
                v-if="member.linkedin != null"
              ></v-img>
              <v-img
                class="mt-2 mt-md-4"
                :src="require('@/assets/img/socialmedia/wikipedia.svg')"
                max-width="40px"
                style="
                  margin-left: auto;
                  margin-right: auto;
                "
                v-if="member.wikipedia != null"
              ></v-img>              
            </v-col>
          </a>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <!-- Section 05 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">05</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our advisors</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card shadow-container wider mt-5 mt-md-10 opaque" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}" style="container-type: inline-size;">
      <div class="shadow" style="font-size: 19cqw">Advisors</div>
      <v-slide-group :class="padding" class="px-0" show-arrows="false">
        <template v-slot:next>
          <v-icon color="#00FFFF" large>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:prev>
          <v-icon color="#00FFFF" large>mdi-chevron-left</v-icon>
        </template>

        <v-slide-item class="wider" v-for="member in advisors">
          <a :href="member.linkedin ?? member.wikipedia" target="_blank" rel="noopener noreferrer">
            <v-col no-gutters>
              <v-img :src="member.img" :width="teamPicSize" />
              <div class="poppins-normal-white-16px bold centered mt-6">{{ member.name }}</div>
              <div class="poppins-normal-white-16px secondary-text centered mt-2">{{ member.job }}</div>
              <v-img
                class="mt-2 mt-md-4"
                :src="require('@/assets/img/socialmedia/linkedin.svg')"
                max-width="40px"
                style="
                  margin-left: auto;
                  margin-right: auto;
                "
                v-if="member.linkedin != null"
              ></v-img>
              <v-img
                class="mt-2 mt-md-4"
                :src="require('@/assets/img/socialmedia/wikipedia.svg')"
                max-width="40px"
                style="
                  margin-left: auto;
                  margin-right: auto;
                "
                v-if="member.wikipedia != null"
              ></v-img>              
            </v-col>
          </a>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <!-- Section 06 -->
    <v-row no-gutters class="wider mt-10 mt-md-16 pt-md-4">
      <v-col no-gutters>
        <div :class="[sectionNumberFont, xPadding]" class="opaque" v-animate-onscroll="{down: 'animated fadeIn'}">06</div>
        <div :class="[sectionHeaderFont, xPadding]" class="opaque mt-2 mt-md-5" v-animate-onscroll="{down: 'animated fadeIn'}">Our perspective</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="reflective-card wider mt-5 mt-md-10 opaque" :class="padding" v-animate-onscroll="{down: 'animated slideInLeftWithFade'}">
      <div :class="normalTextFont" class="mb-md-10 justified">Data processing is an integral part of our society with the ability to transform all industries. Instead of considering it as a vertical service for a specific sector, we recommend looking at it as a horizontal service that drives innovation across all industry sectors.</div>
      <div class="d-flex wider flex-md-row flex-column justify-md-space-between my-10">
        <router-link to="/article?ar-meets-ai" target="_blank">
          <div class="blog-card" :style="{ 'max-width': blogImageSize }">
            <img :src="require('@/assets/img/aboutus/blog/blog1.png')" width="100%" contain />
            <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">Augmented Reality meets AI</div>
            <div class="poppins-normal-white-16px px-6 secondary-text">by Radu Marin</div>
            <div class="poppins-normal-white-14px px-6 pb-12">4 min read</div>
          </div>
        </router-link>
        <router-link to="/article?ml-smart-waste-management" target="_blank">
          <div class="blog-card my-5 my-md-0" :style="{ 'max-width': blogImageSize }">
            <img :src="require('@/assets/img/aboutus/blog/blog2.png')" width="100%" contain />
            <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">ML and smart waste management</div>
            <div class="poppins-normal-white-16px px-6 secondary-text">by Alex Gherghina</div>
            <div class="poppins-normal-white-14px px-6 pb-12">4 min read</div>
          </div>
        </router-link>
        <router-link to="/article?ai-driven-future-of-pharma" target="_blank">
          <div class="blog-card" :style="{ 'max-width': blogImageSize }">
            <img :src="require('@/assets/img/aboutus/blog/blog3.png')" width="100%" contain />
            <div class="max-two-lines poppins-normal-white-22px px-6 pt-6 mb-3">The AI-Driven Future of Pharma - from Innovation to Ethics</div>
            <div class="poppins-normal-white-16px px-6 secondary-text">by Ana Nistor</div>
            <div class="poppins-normal-white-14px px-6 pb-12">5 min read</div>
          </div>
        </router-link>
      </div>
      <div class="wider d-flex justify-center my-5 my-md-10">
        <router-link to="/blog" target="_blank">
          <v-btn elevation="3" outlined rounded class="px-8" :style="{
            'color': 'white',
            'border-color': '#FF5800',
            'z-index': 2,
            'text-transform': 'unset !important',
          }">
            <div class="poppins-semi-bold-white-14px">Read all articles</div>
          </v-btn>
        </router-link>
      </div>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "AboutUs",
  metaInfo: {
    title: "About us"
  },
  components: { Page },
  props: [
    "title",
    "topImage",
    "team",
    "advisors",
  ],
  data() {
    return {
      weAre1: ['We are builders', 'We are engineers', 'We are developers', 'We are designers'],
      weAre2: ['We are thought leaders', 'We are researchers', 'We are educators', 'We are mentors'],
      weAre3: ['We are data engineers', 'We are data scientists', 'We are machine learning engineers', 'We are human'],
    };
  },
  mounted() {
    setTimeout(() => {
        window.scrollBy(0, 1)
        window.scrollBy(0, -1)
    }, 1500);
  },
  computed: {
    sectionNumberFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-normal-white-32px";
        case "sm":
        case "xs":
          return "poppins-normal-white-16px";
      }
    },
    sectionHeaderFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-64px";
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    sectionHeadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-42px";
        case "md":
          return "poppins-bold-white-28px";
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    sectionSubheadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-36px";
        case "sm":
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    largeLabelFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "italic highlight poppins-normal-white-36px";
          case "sm":
          case "xs":
            return "italic highlight poppins-normal-white-28px";
      }
    },
    emphasisTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-20px";
          case "sm":
          case "xs":
            return "poppins-normal-white-16px";
      }
    },
    normalTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-20px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    smallTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-16px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    personaTextFont() {
      switch (this.$vuetify.breakpoint.name) {
          case "xl":
          case "lg":
          case "md":
          return "poppins-normal-white-48px";
          case "sm":
          case "xs":
            return "poppins-normal-white-12px";
      }
    },
    teamPicSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return 460;
        case "sm":
        case "xs":
          return 316;
      }
    },
    padding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "padding-desktop";
        case "sm":
        case "xs":
          return "padding-mobile";
      }
    },
    xPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-padding-desktop";
        case "sm":
        case "xs":
          return "x-padding-mobile";
      }
    },
    iconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "48";
        case "sm":
        case "xs":
          return "36";
      }
    },
    blogImageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "90%";
        case "sm":
        case "xs":
          return "100%";
      }
    },
  },
};
</script>

<style scoped>
.horizontal-divider {
  height: 1px !important;
  width: 100% !important;
  background-color: white !important;
}

.vertical-divider {
  width: 1px !important;
  background-color: white !important;
}

.highlight {
  color: #D554FF;
}

.emphasis {
  color: #FF5800 !important;
  font-weight: 700;
  text-transform: uppercase;
}

.persona {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  text-transform: uppercase;
  white-space: nowrap;
}

.blog-card {
  border-radius: 12px;
  background: #100032;
}

.shadow-container {
  container-type: inline-size;
  position: relative;
}

.shadow {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(59, 49, 101, 0.90);
  font-family: Poppins;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 70%;
  position: absolute;
}

.justified {
  text-align: justify;
  text-justify: inter-word;
}

.slim {
  font-weight: 275;
}

.bold {
  font-weight: 700;
}

.centered {
  text-align: center;
}

.secondary-text {
  color: #CBBEFF;
}

.padding-desktop {
  padding: 80px 160px;
}

.padding-mobile {
  padding: 20px;
}

.x-padding-desktop {
  padding: 0px 160px;
}

.x-padding-mobile {
  padding: 0px;
}

.wider {
  width: 100%;
  max-width: 100% !important;
}

.max-two-lines {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes slideInLeftWithFade {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

.slideInLeftWithFade {
    -webkit-animation-name: slideInLeftWithFade;
    animation-name: slideInLeftWithFade
}

.opaque {
  opacity: 0;
}
</style>
