import Vue from "vue";
import Router from "vue-router";
import { HomeData, TokenomicsData, RoadmapData, BusinessData, AgentData, AboutUsData, LinkTreeData, FaqData } from "./data";
import HomeV2 from "./components/home/Home.vue"
import Agent from "./components/agent/Agent.vue";
import Business from "./components/business/Business.vue";
import AboutUs from "./components/aboutus/AboutUs.vue"
import Roadmap from "./components/roadmap/Roadmap.vue"
import Tokenomics from "./components/tokenomics/Tokenomics.vue"
import Blog from "./components/blog/Blog.vue"
import BlogArticle from "./components/blog/BlogArticle.vue"
import PrivacyPolicy from "./components/misc/PrivacyPolicy.vue"
import CookiePolicy from "./components/misc/CookiePolicy.vue"
import FAQ from "./components/misc/FAQ.vue"
import LinkTree from "./components/misc/LinkTree.vue"
import InstallMobile from "./components/misc/Install.vue"
import AlphaReg from "./components/admin/AlphaReg.vue";
import ReceivingWallet from "./components/misc/ReceivingWallet.vue";
import ArtworkCompetition from "./components/misc/ArtworkCompetition.vue";

const config = require("./config");

Vue.use(Router);

const routes = [
  {
    path: "/agent",
    component: Agent,
    props: { ...AgentData },
  },

  {
    path: "/community",
    component: Agent,
    props: { ...AgentData },
  },

  {
    path: "/alpha",
    component: Agent,
    props: { ...AgentData, displayAlphaRegistration: true },
  },

  {
    path: "/business",
    component: Business,
    props: { ...BusinessData },
  },

  {
    path: "/platform",
    component: Business,
    props: { ...BusinessData },
  },

  {
    path: "/aboutus",
    component: AboutUs,
    props: { ...AboutUsData },
  },

  {
    path: "/tokenomics",
    component: Tokenomics,
    props: { ...TokenomicsData },
  },

  {
    path: "/roadmap",
    component: Roadmap,
    props: { ...RoadmapData },
  },

  {
    path: "/blog",
    component: Blog,
    props: {},
  },

  {
    path: '/article',
    name: "BlogArticle",
    component: BlogArticle,
    props: {},
  },

  {
    path: "/privacy",
    component: PrivacyPolicy,
    props: {}
  },

  {
    path: "/cookies",
    component: CookiePolicy,
    props: {}
  },

  {
    path: "/faq",
    component: FAQ,
    props: { ...FaqData },
  },

  {
    path: "/links",
    component: LinkTree,
    props: { ...LinkTreeData },
  },

  {
    path: "/mobile",
    component: InstallMobile,
    props: {},
  },

  {
    path: "/alphareg",
    component: AlphaReg,
    props: {}
  },

  {
    path: "/wallet",
    component: ReceivingWallet,
    props: {}
  },

  {
    path: "/artwork",
    component: ArtworkCompetition,
    props: {}
  },

  {
    path: "/:pathMatch(.*)*",
    component: HomeV2,
    props: { ...HomeData },
  },
];

export default new Router({
  mode: "history",
  routes: routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
});
