export const getInTouchData = {
    successMessage: "Thanks! We'll keep in touch.",
    errorMessage: "There was an error, please try again later.",
    marketing: 'I agree to receive news, updates, promotional info and other marketing updates from Timeworx.io'
};

export const socialLinks = {
    email: 'mailto:contact@timeworx.io',
    twitter: 'https://twitter.com/timeworx_io',
    telegram: 'https://t.me/timeworx',
    discord: 'https://discord.gg/4t2ZCCY5zu',
    linkedin: 'https://www.linkedin.com/company/timeworx-io/',
    medium: 'https://medium.com/@radum.tix',
    reddit: 'https://www.reddit.com/user/Timeworx_io/',
    galxe: 'https://app.galxe.com/quest/Timeworx',
    instagram: 'https://www.instagram.com/timeworx.io/',
    tiktok: 'https://www.tiktok.com/@timeworx.io',
    egldcommunity: 'https://egld.community/projects/timeworx'
};

export const footerData = {
    x2022TimeworxAllRightsReserved: "@ 2022 Timeworx, All Rights Reserved",
    termsAndConditions: "Terms and conditions",
    privacyPolicy: "Privacy Policy",
    faq: "FAQ",
    contactDetails: "Contact details",
    getInTouchProps: getInTouchData,
    socialProps: socialLinks,
};

export const AlphaRegistrationData = {
    titleLine1: 'Get registered',
    titleLine2: 'for early app testing!',
    body: 'Fill in this form and become one of the first people to get access to the Timeworx.io app. After submitting the form, we will send you an email with detailed instructions for installing the app.\n\nEarly app testing is carried out on the Injective Testnet and on the MultiversX Devnet.\n\nChoose your preferred mobile OS:',
    button: 'Register',
    successMessage: "We've sent you instructions to your email address.",
    errorMessage: "There was an error, please try again later.",
    img: require('@/assets/img/agent/alpha-registration-form-graphic.png'),
    lazyImg: require('@/assets/img/agent/alpha-registration-form-graphic.png'),
    marketing: "I agree to receive news, updates, promotional info and other marketing updates from Timeworx.io",
};

export const AgentData = {
    title: 'Which tasks is the human mind better at than computers?',
    alpha: AlphaRegistrationData
};

export const tokenomicsCategories = [
    { 
        order: '0',
        label: 'Community',
        value: '165,000,000 TIX',
        percentage: 33,
        description: 'The largest allocation is dedicated to our community and ecosystem, through staking rewards, aidrops for our loyal users and support for community projects.',
        color: '#7B63DF',
        subcategories: [
            {
                label: 'Staking Rewards',
                value: '75,000,000 TIX',
                percentage: 15,
                description: 'Rewards offered to users that stake TIX for securing the platform'
            },
            {
                label: 'Airdrops',
                value: '75,000,000 TIX',
                percentage: 15,
                description: 'Tokens distributed to the community for showing appreciation and for offering incentives'
            },
            {
                label: 'Community Projects',
                value: '15,000,000 TIX',
                percentage: 3,
                description: 'Tokens to be distributed to innovative community projects that we will be supporting'
            },
        ],
    },
    {
        order: '1',
        label: 'Growth',
        value: '140,000,000 TIX',
        percentage: 28,
        description: 'The Growth allocation is reserved for ensuring the liquidity of our ecosystem throughout time and financing our future marketing and sales pipelines. Unused funds are burned once the next amount is released.',
        color: '#D554FF',
        subcategories: [
            {
                label: 'Liquidity Reserve',
                value: '50,000,000 TIX',
                percentage: 10,
                description: 'Reserve for listing on exchanges'
            },
            {
                label: 'Marketing',
                value: '40,000,000 TIX',
                percentage: 8,
                description: 'Exclusive for marketing efforts which will grow exponetially throughout time'
            },
            {
                label: 'Company Reserve',
                value: '50,000,000 TIX',
                percentage: 10,
                description: '“Rainy-day” fund to only be used in case of extreme emergencies'
            },
        ],
    },
    {
        order: '2',
        label: 'Public Sales',
        value: '29,604,000 TIX',
        percentage: 6,
        description: "As true believers of DeFi, we have reserved an allocation to allow our community to invest in our Public Sale.",
        color: '#8B00E7',
    },
    {
        order: '3',
        label: 'Private Sales',
        value: '55,396,000 TIX',
        percentage: 11,
        description: 'Tokens allocated for our seed and private sale investors to reward our oldest and most loyal Partners.',
        color: '#B09EFF',
        subcategories: [
            {
                label: 'Seed',
                value: '52,000,000 TIX',
                percentage: 10.4,
                description: 'Tokens allocated for seed funding round'
            },
            {
                label: 'Private Sale',
                value: '3,396,000 TIX',
                percentage: 0.68,
                description: 'Tokens allocated for the Private Sale founding round'
            },
        ],
    },
    {
        order: '4',
        label: 'Bootstrapping',
        value: '50,000,000 TIX',
        percentage: 10,
        description: "Pledge for supporting academic research and for building our initial use cases for acquiring new business. All of these tokens will eventually find their way into the hands of the community as rewards for solving tasks.",
        color: '#A53CFF',
    },
    {
        order: '5',
        label: 'Team',
        value: '45,000,000 TIX',
        percentage: 9,
        description: 'Incentive for upholding the highest standards in the continuous development of our platform, as well as for growing the team, for years to come.',
        color: '#957DFB',
    },
    {
        order: '6',
        label: 'Advisors',
        value: '15,000,000 TIX',
        percentage: 3,
        description: "Rewards for the invaluable advice, guidance and insights that we have received and continue to expect from our advisors.",
        color: '#CB93FF',
    },
];

export const TokenomicsData = {
    title: 'Tokenomics',
    topImage: require('@/assets/img/top/tokenomics.png'),
    topContent: 'At Timeworx.io, we leverage the power of decentralized environments where humans can utilize their time to solve simple and repetitive tasks that machines cannot solve. Humans can monetize this time by earning TIX - our product utility currency. Ultimately, the task solutions provide labeled data for machine learning. The TIX utility token is used to power the data processing platform, can only be used for requesting services from Timeworx.io, and can only be obtained by solving tasks. Aside from enabling transactions, the TIX token plays a vital role in our ecosystem by offering other features like staking for securing trust in the platform and receiving rewards in exchange. This comprehensive strategy for the token function maintains its value and enhances user involvement with the platform.',
    tokenomicsCategoriesProps: tokenomicsCategories,
};

export const FaqData = {
    title: 'FAQ',
    topImage: require('@/assets/img/top/faq.png'),
}


export const roadmapItems = [
    {
        label: 'Out in the wild', 
        content: "This is it! We've made our debut and we're ready to talk about what we've got planned.",
        passed: true,
        icon: require('@/assets/img/roadmap/rocket-cyan-left.svg'),
    },
    { 
        label: 'Q4 2022 - Proof of Concept', 
        content: "We’re doubling down and releasing the initial version of our platform. We’re preparing a few task templates that will allow us to build a rewarding model for our Agents.",
        passed: true,
        icon: require('@/assets/img/roadmap/proof-concept-cyan.svg'),
    },
    { 
        label: 'Q1 2023 - Mobile App Alpha', 
        content: "Those few task templates are now many. We are now validating our internal architecture and proving our state of the art algorithms that ensure accuracy and speed in generating data.",
        passed: true,
        icon: require('@/assets/img/roadmap/alpha-cyan-left.svg'),
    },
    { 
        label: 'Q1 2023 - New partnership', 
        content: "We're proud to partner up with the most iconic academic representative in the Romanian IT&C landscape: the Faculty of Automatic Control and Computer Science from UPB.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    { 
        label: 'Q2 2023 - New tasks, new challenges', 
        content: "We're rolling out new tasks in the app to help our AI learn the ropes and easily do those human things that are so simple for us but are oh-so-tricky for the machines.",
        passed: true,
        icon: require('@/assets/img/roadmap/tasks-cyan-left.svg'),
    },
    { 
        label: 'Q2 2023 - New partnership', 
        content: "Together with EnterTeq, we are building and training machine learning models to help the retail industry read, identify, extract & analyze information from receipts.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    {
        label: 'Q3 2023 - Loyalty Program', 
        content: "We are grateful for everyone’s focus on testing the limits of the platform. That’s why we’re announcing a few ways in which we will be rewarding your current efforts and incentivising our future growth.",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan-left.svg'),
    },
    { 
        label: 'Q3 2023 - New partnership', 
        content: "Partner Staking have not only been our incubator, supporting growth, acting as pre-seed fund and promoter for us, but also our infrastructure backbone, providing the servers and hosting the services we need.",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan.svg'),
    },
    { 
        label: 'Q4 2023 - Injective', 
        content: "We are live on the Injective Testnet! With lightning fast transaction times, extremely low fees, and a thriving community, Injective is an ecosystem where our product can properly develop its true potential.",
        passed: true,
        icon: require('@/assets/img/roadmap/injective-cyan-left.svg'),
    },
    { 
        label: 'Q1 2024 - Whitepaper',
        content: "We know everyone’s curious to get more details from behind the scenes, and here they are.",
        passed: true,
        icon: require('@/assets/img/roadmap/white-paper-cyan.svg'),
    },
    {
        label: 'Q2 2024 - Loyalty Program 2.0', 
        content: "Simply put, you’ve wowed us. We've raised the stakes and increased our airdrop allocation for you, our amazing community. Join in, solve tasks, and let’s keep this party rocking!",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan-left.svg'),
    },
    {
        label: 'Q3 2024 - Even more tasks!', 
        content: "From Sarcasm Detection to Human Pose Estimation, we're revolutionizing the data processing world with a wide variety of tasks which will shape the future of AI. Have you explored them in the app yet? If not, it's time to start your journey!",
        passed: true,
        icon: require('@/assets/img/roadmap/tasks-cyan.svg'),
    },
    {
        label: 'Q4 2024 - New partnership', 
        content: "We're very excited to bring AI and drive utility into the ecosystem alongside DojoSwap, one of the OG builders from Injective",
        passed: true,
        icon: require('@/assets/img/roadmap/partnership-cyan-left.svg'),
    },
    {
        label: 'Q4 2024 - Airdrop portal launch', 
        content: "Say hello to our freshly baked portal, where the community can check airdrop eligibility and earnings in real time ",
        passed: true,
        icon: require('@/assets/img/roadmap/loyalty-cyan.svg'),
    },
    {
        label: 'Q1 2025 - Community Token Sale', 
        content: "This is your limited opportunity to be among the first investors in Timeworx.io! Our exclusive $TIX sale on Republic via RegD is now open – but for now, it’s available ONLY to our early supporters!",
        passed: true,
        animated: true,
        icon: require('@/assets/img/roadmap/celebrate-left.svg'),
    },
    { 
        label: 'Q1 2025 - Public sale', 
        content: "Launchpad commitments have been confirmed! Everyone will get their chance to invest in our platform!",
        passed: false,
        animated: true,
        icon: require('@/assets/img/roadmap/public-sale-orange.svg'),
    },
    { 
        label: 'Q2 2025 - Mainnet launch', 
        content: "It’s been a blast! We’re now ready to build the future of AI on Injective and MultiversX. We’re confident Timeworx.io can now scale to meet any demand and you can finally monetize all of that invested time.",
        passed: false,
        icon: require('@/assets/img/roadmap/release-orange.svg'),
    },
    {
        label: 'Q2 2025 - Agent mobile app', 
        content: "We’re almost there! Maybe the most critical step in the product to launch is to make our mobile applications publicly available on the Apple App Store and the Google Play Store",
        passed: false,
        icon: require('@/assets/img/roadmap/beta-orange-left.svg'),
    },
    {
        label: 'Q2 2025 - Gamification features', 
        content: "Implementing awards & penalties, custom-tailored improvements paths, leaderboards and challenges for incentivising Human Agents will be a game changer.",
        passed: false,
        icon: require('@/assets/img/roadmap/beta-orange-left.svg'),
    },
    { 
        label: 'Q3 2025 - Business Console app', 
        content: "We’re finally unveiling the powerful web app that enables businesses to specify their data processing needs, bringing in new tasks and new opportunities for the entire community.",
        passed: false,
        icon: require('@/assets/img/roadmap/console-orange-left.svg'),
    },
    { 
        label: 'Q3 2025 - University Outreach Program', 
        content: "We will discover & fund academic research projects that are in dire need of data processing. With a plethora of data and use-cases, these flagship projects will provide additional requirements for our platform.",
        passed: false,
        icon: require('@/assets/img/roadmap/console-orange-left.svg'),
    },
    { 
        label: 'Q4 2025 - Agent web app', 
        content: "We will deliver a truly multi-platform implementation for our data processing crowdsourcing app by supporting both web and mobile natively.",
        passed: false,
        icon: require('@/assets/img/roadmap/console-orange-left.svg'),
    },
    { 
        label: 'Q4 2025 - Educational features', 
        content: "We will be integrating educational modules in our mobile application that will incentivise the adoption of our platform to a growing community of Human Agents.",
        passed: false,
        icon: require('@/assets/img/roadmap/console-orange-left.svg'),
    },
    { 
        label: 'Into the Great Unknown', 
        content: "We’re far from done! In fact, we’re just beginning to add some exciting new features to our platform that will make it even better. We cannot add everything in one page, so make sure to stick with us while we reveal more.",
        passed: false,
        icon: require('@/assets/img/roadmap/unknown-orange.svg'),
    },
]

export const RoadmapData = {
    title: 'Roadmap',
    topImage: require('@/assets/img/top/roadmap.png'),
    roadmapItems: roadmapItems,
};

export const BusinessData = {
    title: 'Process your data with speed and efficiency',
};

export const team = [
    {
        name: 'Alexandru Gherghina, Drd',
        job: 'CEO & Co-Founder',
        img: require('@/assets/img/aboutus/team/alex.png'),
        linkedin: 'https://www.linkedin.com/in/alexg1337',
    },
    {
        name: 'Radu Marin, PhD',
        job: 'COO & Co-Founder',
        img: require('@/assets/img/aboutus/team/radu.png'),
        linkedin: 'https://www.linkedin.com/in/radu-corneliu-marin-62897b59',
    },
    {
        name: 'Ionut Popescu',
        job: 'Chairman of the Board & Co-Founder',
        img: require('@/assets/img/aboutus/team/ionut.png'),
        wikipedia: 'https://ro.wikipedia.org/wiki/Ionu%C8%9B_Popescu',
    },
    {
        name: 'Andreea Baron',
        job: 'UI/UX Officer',
        img: require('@/assets/img/aboutus/team/andreea.png'),
        linkedin: 'https://www.linkedin.com/in/andreea-baron',
    },
    {
        name: 'Robert Timisica',
        job: 'Lead Android Engineer',
        img: require('@/assets/img/aboutus/team/robert2.png'),
        linkedin: 'https://www.linkedin.com/in/robert-timisica/',
    },
    {
        name: 'Silviu Stoican',
        job: 'Lead iOS Engineer',
        img: require('@/assets/img/aboutus//team/silviu.png'),
        linkedin: 'https://www.linkedin.com/in/silviu-st-50941175/',
    },
    {
        name: 'Ana-Maria Nistor',
        job: 'Marketing Officer',
        img: require('@/assets/img/aboutus/team/ana.png'),
        linkedin: 'https://www.linkedin.com/in/anamarianistor/',
    },
    {
        name: 'Cristina Foarfa',
        job: 'Wordsmith',
        img: require('@/assets/img/aboutus/team/cristina.png'),
        linkedin: 'https://www.linkedin.com/in/cristina-foarfa-6411086/',
    },
];

export const advisors = [
    {
        name: 'Andrei Stan, Drd',
        job: 'MBA, FCCA',
        title: 'Product',
        img: require('@/assets/img/aboutus/team/andi.png'),
        linkedin: 'https://www.linkedin.com/in/andi-stan/',
    },
    {
        name: 'Arturas Feiferas',
        job: 'Banking Professional',
        title: 'Finance & Compliance',
        img: require('@/assets/img/aboutus/team/arturas.png'),
        linkedin: 'https://www.linkedin.com/in/arturas-feiferas-8415582b/',
    },
    {
        name: 'Prof. Dr. Eng. Ciprian Dobre',
        job: 'Vice-Rector at UNSTPB',
        title: 'Research & Academics',
        img: require('@/assets/img/aboutus/team/ciprian.png'),
        linkedin: 'https://www.linkedin.com/in/cipriandobre/',
    },
];

export const AboutUsData = {
    title: 'About us',
    topImage: require('@/assets/img/aboutus/top.png'),
    team: team,
    advisors: advisors,
};

export const GetInTouchData = {
    title: [
        'Keep Me Updated',
        'Schedule a meeting with our consultants',
        'Get in touch',
    ],
};

export const LinkTreeData = {
    homepage: "https://timeworx.io",
    userjourney: "http://timeworx.io/agent",
    twitter: socialLinks.twitter,
    telegram: socialLinks.telegram,
    discord: socialLinks.discord,
    linkedin: socialLinks.linkedin,
    instagram: socialLinks.instagram,
    tiktok: socialLinks.tiktok,
    galxe: socialLinks.galxe,
    egldcommunity: socialLinks.egldcommunity
}
