<template>
  <Page title="Wallet Instructions">
    <v-row no-gutters justify="center" class="mb-16">
      <v-col no-gutters>
        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>The $TIX token will be minted on the <a href="https://injective.com/" target="_blank"><u>Injective</u></a> and <a href="https://multiversx.com/" target="_blank"><u>MultiversX</u></a> networks. You will need to use a wallet compatible with either of these two networks in order to receive your tokens, based on your preference.</p>
        
        </div>

        <h2 :class="[subtitleFont, xSpacing]">Injective</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>The first step to creating an Injective Wallet is to download a non-custodial crypto wallet that is compatible with Injective. There is a wide variety of wallets available, so we kindly advise you to browse this <a href="https://blog.injective.com/how-to-create-an-injective-wallet-2/" target="_blank"><u>tutorial</u></a>. When copying the address, keep in mind it always starts with the <span style="font-style: italic;">inj1</span> prefix.</p>
          <p>We recommend the following:</p>
          <h3 :class="sectionSubheadlineFont" class="my-md-3">Cosmos-Based Wallets</h3>
          <p>Injective supports the leading wallets compatible with Cosmos and IBC, including:</p>
          <ul class="priv-list">
            <li><a href="https://www.keplr.app/" target="_blank"><u>Keplr</u></a>: please refer to this <a href="https://help.keplr.app/articles/new-wallet-extension" target="_blank"><u>guide for web</u></a> and to this <a href="https://help.keplr.app/articles/new-wallet-mobile" target="_blank"><u>guide for mobile</u></a></li>
            <li><a href="https://www.leapwallet.io/" target="_blank"><u>Leap</u></a>: please refer to this <a href="https://www.leapwallet.io/blog/install-leap-wallet?35142213_page=3" target="_blank"><u>guide for both web and mobile</u></a></li>
          </ul>
          
          <h3 :class="sectionSubheadlineFont" class="my-3">Ethereum-Based Wallets</h3>
          <p>Injective addresses are native (compatible) with Ethereum, allowing you to use Ethereum-native wallets:</p>
          <ul class="priv-list">
            <li><a href="https://metamask.io/" target="_blank"><u>MetaMask</u></a>: please refer to this <a href="https://support.metamask.io/start/getting-started-with-metamask/" target="_blank"><u>guide for both web and mobile</u></a></li>
            <li><a href="https://www.okx.com/en-eu/web3/rewritethesystem" target="_blank"><u>OKX</u></a>: please refer to this <a href="https://www.okx.com/en-eu/learn/how-to-create-an-okx-wallet" target="_blank"><u>guide for both web and mobile</u></a></li>
          </ul>
          <p>After creating your Ethereum-based wallet, you will need to <a href="https://blog.injective.com/how-to-create-an-injective-wallet-2/#step-2-connect-your-wallet-to-injective" target="_blank"><u>connect it to Injective</u></a> to obtain your Injective address.</p>
          <p><span class="bold">IMPORTANT NOTE:</span> Although Injective addresses are compatible with Ethereum, $TIX is not an ERC-20 token, therefore, you will not be able to directly see it in your wallets. You will first have to connect to the <a href="https://blog.injective.com/how-to-create-an-injective-wallet-2/#step-2-connect-your-wallet-to-injective" target="_blank"><u>Injective Hub</u></a>, and then you will be able to see it your tokens in the Wallet section</p>
        </div>

        <h2 :class="[subtitleFont, xSpacing]">MultiversX</h2>

        <div :class="[contentFont, spacing]" class="reflective-card">
          <p>MultiversX supports a <a href="https://docs.multiversx.com/wallet/overview" target="_blank"><u>long list of wallets</u></a>, from which we recommend:</p>
          <ul class="priv-list">
            <li><a href="https://wallet.multiversx.com/" target="_blank"><u>Web Wallet</u></a>: please refer to this <a href="https://docs.multiversx.com/wallet/web-wallet/" target="_blank"><u>guide</u></a></li>
            <li><a href="https://xportal.com/" target="_blank"><u>xPortal</u></a>: please refer to this <a href="https://xportal.com/blog/post/what-is-xportal-and-how-to-install-it" target="_blank"><u>guide</u></a></li>
          </ul>
          <p> When copying the address, keep in mind it always starts with the <span style="font-style: italic;">prefix</span>.</p>
        </div>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import Page from "../Page.vue";

export default {
  name: "WalletInstructions",
  metaInfo: {
    title: "Wallet Instructions"
  },
  components: { Page },
  props: [],
  computed: {
    contentFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-normal-white-18px";
        case "md":
        case "sm":
          if (!this.$isMobile()) {
            return "poppins-normal-white-16px";
          } else {
            return "poppins-normal-white-15px";
          }
        case "xs":
          return "poppins-normal-white-15px";
      }
    },
    subtitleFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-42px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-24px";
      }
    },
    headlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
          return "poppins-bold-white-64px";
        case "md":
        case "sm":
        case "xs":
          return "poppins-bold-white-32px";
      }
    },
    sectionSubheadlineFont() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "poppins-bold-white-26px";
        case "sm":
        case "xs":
          return "poppins-bold-white-20px";
      }
    },
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "spacing-desktop";
        case "sm":
        case "xs":
          return "spacing-mobile";
      }
    },
    xSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
        case "lg":
        case "md":
          return "x-spacing-desktop";
        case "sm":
        case "xs":
          return "x-spacing-mobile";
      }
    },
  },
};
</script>

<style scoped>
.priv-list {
  list-style-type: none;
}

.priv-list li:before {
 content: "\2022";
 padding-right: 0.5em;
 color: white;
}

.highlight {
  color: #D554FF;
}

.spacing-desktop {
  padding: 80px 160px;
  margin-bottom: 80px;
}

.spacing-mobile {
  padding: 20px;
  margin-bottom: 40px;
}

.spacing-last {
  margin-bottom: 0px !important;
}

.x-spacing-desktop {
  padding: 0px 160px;
  margin-bottom: 40px !important;
}

.x-spacing-mobile {
  padding: 0px 20px;
  margin-bottom: 20px !important;
}

* a {
  color: inherit !important;
}

.bold {
  font-weight: 700;
}
</style>
